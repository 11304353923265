import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import { Link } from "react-router-dom";

// UI
import TableRow from "../../ui/TableRow";
import TableCell from "../../ui/TableCell";
import PageFrequency from "./PageFrequency";
import NextScreenshotInfo from "./NextScreenshotInfo";

class PageItem extends React.Component {

  componentWillMount() {
    // Set the user's timezone so Moment.js dates are correctly formatted
    const timezone = localStorage.getItem("timezone");

    if(timezone) {
      Moment.globalTimezone = timezone;
    }
  }
  
  render() {
    
    return (
      <TableRow className={!this.props.data.active ? 'table-row--inactive' : ''}>
        <TableCell>
          <Link to={`/page/${this.props.data.id}`} className="subtle-link">
            <strong>{this.props.data.name}</strong>
          </Link>
        </TableCell>
        <TableCell>
          <a href={this.props.data.url} target="_blank" rel="noopener noreferrer" className="subtle-link">{this.props.data.url}</a>
        </TableCell>
        <TableCell>
          <PageFrequency data={this.props.data} />
        </TableCell>
        <TableCell>
          <NextScreenshotInfo data={this.props.data} />
        </TableCell>
        <TableCell>
          <span>{this.props.data.total_capture_count}</span>
        </TableCell>
        <TableCell>
          { this.props.data.active ? 'Active' : 'Inactive' }
        </TableCell>
        <TableCell ra xxhoverable>
          <Link to={`/page/${this.props.data.id}`} className="btn btn--secondary btn--sm">View</Link>
        </TableCell>
      </TableRow>
    );
  }
}

export default PageItem;