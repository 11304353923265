import React, { Component } from "react";

class TableRow extends Component {
  render() {
    return (
      <div className={`table-row ${this.props.header ? 'table-row--header' : ''} ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default TableRow;