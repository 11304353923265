import React from "react";
import { withRouter, Link } from "react-router-dom";
import http from "../../utility/http";
import plan, { clearCachedPlanInfo } from "../../factories/plan";

// UI
import SubNav, { SubNavText, SubNavLink, SubNavSep } from "../../ui/SubNav";
import Panel, { PanelTitle } from "../../ui/Panel";
import Loading from "../../ui/Loading";
import TakeScreenshotResult from "./TakeScreenshotResult";

class ScreenshotPage extends React.Component {
  
  constructor() {
    super();

    this.state = {
      page: {},
      loading: true,
      panelTitle: "Screenshot in Progress",
      panelLoading: true,
      success: false,
      error: ""
    };
  }
  
  componentDidMount() {
    this.props.setSection("screenshots");

    const nowEpoch = new Date().valueOf();
    const fiveSecondsAgo = nowEpoch - 5000;
    
    if(fiveSecondsAgo < this.props.match.params.timestamp) {
      
      // Get the user's plan information
      plan(resp => {
        this.setState({ plan: resp });
        this.getPage();
      });
      
    } else {
      this.props.history.push(`/page/${this.props.match.params.id}`);
    }

  }

  getPage() {

    // Get this page
    http.get(`/page/${this.props.match.params.id}/`).then(resp => {

      // Add page to state
      this.setState({
        page: resp.data,
        loading: false
      });

      this.takeScreenshot();
      
    }).catch((error) => {
      this.props.history.push("/");
    });
  }

  takeScreenshot() {

    // Take the screenshot
    http.get(`/screenshot/manual-capture/${this.props.match.params.id}/`).then(resp => {
      
      this.setState({
        success: true,
        panelLoading: false,
        panelTitle: "Screenshot Complete",
        errors: resp.data.errors || []
      });

      // If this screenshot used proxies, clear the plan usage cache
      if(this.state.page.proxy_location) {
        clearCachedPlanInfo();
      }
      
    }).catch((error) => {

      let errorMessage = <p>Sorry, something went wrong. Please try again.</p>;

      try {
        if(error.response.data.exception === "ProxyLimitReachedException") {
          errorMessage = <p>You've reached your monthly proxy bandwidth limit of <strong>{this.state.plan.plan.limits.proxy_bandwidth.toLocaleString('en-US')} MB</strong>. Visit the <Link to="/settings">Settings</Link> page for more info.</p>
        } else if(error.response.data.exception === "TunnelConnectionFailedException") {
          errorMessage = <p>Proxy connection failed - please try again or contact support: <a href="mailto:support@screenlapse.com">support@screenlapse.com</a></p>
        } else if(error.response.data.exception === "TimeoutException" || error.response.data.exception === "WebDriverException") {
          errorMessage = <p>The URL you entered is not responding. Please ensure it is correct and try again.</p>
        } else if(error.response.data.exception === "NoSuchElementException") {
          errorMessage = <p>We couldn't find the element you specified:</p>
        }
      } catch(err) {
        console.error(err);
      }

      this.setState({
        success: false,
        panelLoading: false,
        panelTitle: "Screenshot Failed",
        error: errorMessage,
        errors: error.response.data.errors || []
      });
      
    });
  }

  render() {

    const Content = () => {
      
      if(this.state.panelLoading) {
        return (
          <p>Capturing a screenshot now. This could take up to 30 seconds.</p>
        );
      }
      
      return <TakeScreenshotResult success={this.state.success} error={this.state.error} errors={this.state.errors} />;
    };

    if(this.state.loading) {
      return null;
    } else {

      return (
        <div>

          <SubNav>
            <SubNavLink to="/">Pages</SubNavLink>
            <SubNavSep />
            <SubNavLink to={`/page/${this.props.match.params.id}`}>{this.state.page.name}</SubNavLink>
            <SubNavSep />
            <SubNavText>Take Screenshot</SubNavText>
          </SubNav>

          <Panel wide centered hasTitle>

            <PanelTitle>{this.state.panelTitle}</PanelTitle>

            <Content/>
          
            <Loading loading={this.state.panelLoading} />
            
          </Panel>

          <div className="secondary-action-area secondary-action-area--no-border centered">

            <h2>Keep working while this runs:</h2>

            <p>
              <Link to="/" className="btn btn--secondary btn--sm btn--dual">View Page List</Link>
              <Link to="/page/add" className="btn btn--sm btn--dual">Add New Page</Link>
            </p>

          </div>
          
        </div>
      );
    }
  }
}

export default withRouter(ScreenshotPage);