/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import Emoji from "../ui/Emoji";

class PricingPlans extends React.Component {
  render() {
    return (
      <div className="pricing-plans">
      
        <span className={`pricing-plan ${this.props.static ? 'pricing-plan--static' : ''} ${this.props.plan === 'starter' ? 'pricing-plan--selected' : ''}`} onClick={(e) => this.props.selectPlan(e, "starter")}>
          {this.props.currentPlan === 'starter' ? <span className="pricing-plan__active-subscription">Your Active Subscription</span> : null}
          <h3 className="pricing-plan__title">Starter</h3>
          <h4 className="pricing-plan__price">
            <span className="pricing-plan__price-currency">$</span>
            <span className="pricing-plan__price-number">19</span>
            <span className="pricing-plan__price-frequency">/mo</span>
          </h4>
          <ul className="pricing-features">
            <li>
              <Emoji>✅</Emoji>
              Up to <strong>5</strong> web pages
            </li>
            <li>
              <Emoji>🌎</Emoji>
              <strong>250 MB</strong> proxy bandwidth
            </li>
            <li>
              <Emoji>📅</Emoji>
              Daily / weekly / monthly scheduling
            </li>
            <li>
              <Emoji>⏳</Emoji>
              <strong>1</strong> page with hourly scheduling
            </li>
            <li>
              <Emoji>🔂</Emoji>
              Sync to Dropbox
            </li>
            <li>
              <Emoji>👨🏻‍💻</Emoji>
              Email support
            </li>
            <li>
              <Emoji>💬</Emoji>
              Chat support
            </li>
          </ul>
        </span>

        <span className={`pricing-plan ${this.props.static ? 'pricing-plan--static' : ''} ${this.props.plan === 'professional' ? 'pricing-plan--selected' : ''}`} onClick={(e) => this.props.selectPlan(e, "professional")}>
          {this.props.currentPlan === 'professional' ? <span className="pricing-plan__active-subscription">Your Active Subscription</span> : null}
          <h3 className="pricing-plan__title">Professional</h3>
          <h4 className="pricing-plan__price">
            <span className="pricing-plan__price-currency">$</span>
            <span className="pricing-plan__price-number">49</span>
            <span className="pricing-plan__price-frequency">/mo</span>
          </h4>
          <ul className="pricing-features">
            <li>
              <Emoji>✅</Emoji>
              Up to <strong>25</strong> web pages
            </li>
            <li>
              <Emoji>🌎</Emoji>
              <strong>500 MB</strong> proxy bandwidth
            </li>
            <li>
              <Emoji>📅</Emoji>
              Daily / weekly / monthly scheduling
            </li>
            <li>
              <Emoji>⏳</Emoji>
              <strong>1</strong> page with hourly scheduling
            </li>
            <li>
              <Emoji>🔂</Emoji>
              Sync to Dropbox
            </li>
            <li>
              <Emoji>👨🏻‍💻</Emoji>
              Email support
            </li>
            <li>
              <Emoji>💬</Emoji>
              Chat support
            </li>
          </ul>
        </span>

        <span className={`pricing-plan ${this.props.static ? 'pricing-plan--static' : ''} ${this.props.plan === 'professional_plus' ? 'pricing-plan--selected' : ''}`} onClick={(e) => this.props.selectPlan(e, "professional_plus")}>
          {this.props.currentPlan === 'professional_plus' ? <span className="pricing-plan__active-subscription">Your Active Subscription</span> : null}
          <h3 className="pricing-plan__title">Professional Plus</h3>
          <h4 className="pricing-plan__price">
            <span className="pricing-plan__price-currency">$</span>
            <span className="pricing-plan__price-number">89</span>
            <span className="pricing-plan__price-frequency">/mo</span>
          </h4>
          <ul className="pricing-features">
            <li>
              <Emoji>✅</Emoji>
              Up to <strong>50</strong> web pages
            </li>
            <li>
              <Emoji>🌎</Emoji>
              <strong>750 MB</strong> proxy bandwidth
            </li>
            <li>
              <Emoji>📅</Emoji>
              Daily / weekly / monthly scheduling
            </li>
            <li>
              <Emoji>⏳</Emoji>
              <strong>1</strong> page with hourly scheduling
            </li>
            <li>
              <Emoji>🔂</Emoji>
              Sync to Dropbox
            </li>
            <li>
              <Emoji>👨🏻‍💻</Emoji>
              Email support
            </li>
            <li>
              <Emoji>💬</Emoji>
              Chat support
            </li>
          </ul>
        </span>

        <span className={`pricing-plan ${this.props.static ? 'pricing-plan--static' : ''} ${this.props.plan === 'business' ? 'pricing-plan--selected' : ''}`} onClick={(e) => this.props.selectPlan(e, "business")}>
          {this.props.currentPlan === 'business' ? <span className="pricing-plan__active-subscription">Your Active Subscription</span> : null}
          <h3 className="pricing-plan__title">Business</h3>
          <h4 className="pricing-plan__price">
            <span className="pricing-plan__price-currency">$</span>
            <span className="pricing-plan__price-number">179</span>
            <span className="pricing-plan__price-frequency">/mo</span>
          </h4>
          <ul tag="ul" className="pricing-features">
            <li>
              <Emoji>✅</Emoji>
              Up to <strong>100</strong> web pages
            </li>
            <li>
              <Emoji>🌎</Emoji>
              <strong>1,500 MB</strong> proxy bandwidth
            </li>
            <li>
              <Emoji>📅</Emoji>
              Daily / weekly / monthly scheduling
            </li>
            <li>
              <Emoji>⏳</Emoji>
              <strong>5</strong> pages with hourly scheduling
            </li>
            <li>
              <Emoji>🔂</Emoji>
              Sync to Dropbox
            </li>
            <li>
              <Emoji>👨🏻‍💻</Emoji>
              Email support
            </li>
            <li>
              <Emoji>💬</Emoji>
              Chat support
            </li>
          </ul>
        </span>

        <span className={`pricing-plan ${this.props.static ? 'pricing-plan--static' : ''} ${this.props.plan === 'business_plus' ? 'pricing-plan--selected' : ''}`} onClick={(e) => this.props.selectPlan(e, "business_plus")}>
          {this.props.currentPlan === 'business_plus' ? <span className="pricing-plan__active-subscription">Your Active Subscription</span> : null}
          <h3 className="pricing-plan__title">Business Plus</h3>
          <h4 className="pricing-plan__price">
            <span className="pricing-plan__price-currency">$</span>
            <span className="pricing-plan__price-number">399</span>
            <span className="pricing-plan__price-frequency">/mo</span>
          </h4>
          <ul tag="ul" className="pricing-features">
            <li>
              <Emoji>✅</Emoji>
              Up to <strong>250</strong> web pages
            </li>
            <li>
              <Emoji>🌎</Emoji>
              <strong>3,000 MB</strong> proxy bandwidth
            </li>
            <li>
              <Emoji>📅</Emoji>
              Daily / weekly / monthly scheduling
            </li>
            <li>
              <Emoji>⏳</Emoji>
              <strong>10</strong> pages with hourly scheduling
            </li>
            <li>
              <Emoji>🔂</Emoji>
              Sync to Dropbox
            </li>
            <li>
              <Emoji>👨🏻‍💻</Emoji>
              Email support
            </li>
            <li>
              <Emoji>💬</Emoji>
              Chat support
            </li>
          </ul>
        </span>
      
      </div>
    );
  }
}

export default PricingPlans;