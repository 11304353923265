import React from "react";

// Icons
import xIcon from "../../ui/img/x.svg";
import cursorClickIcon from "../../ui/img/cursor-default-click.svg";
import timerIcon from "../../ui/img/timer.svg";
import globeIcon from "../../ui/img/globe.svg";
import eyeClosedIcon from "../../ui/img/eye-closed.svg";
import addIcon from "../../ui/img/add-square.svg";
import minusIcon from "../../ui/img/minus-square.svg";
import arrowRight from "../../ui/img/key-arrow-right.svg";
import formTextboxIcon from "../../ui/img/form-textbox.svg";
import lockIcon from "../../ui/img/lock.svg";

class CustomActionItem extends React.Component {
  render() {

    switch(this.props.action.type) {
      case "click":
        return (
          <div className={`custom-action-wrapper ${this.props.index === this.props.draggedItemIndex ? 'custom-action-wrapper--being-dragged' : ''} ${this.props.index === this.props.draggedOverIndex ? 'custom-action-wrapper--dragged-over' : ''} ${this.props.index > this.props.draggedItemIndex ? 'custom-action-wrapper--dragged-below' : 'custom-action-wrapper--dragged-above'}`}>
            <div className="custom-action" draggable onDragStart={this.props.onDragStart} onDragEnter={this.props.onDragEnter} onDragEnd={this.props.onDragEnd}>
              <div className="custom-action__title">
                <img src={ cursorClickIcon } alt="Cursor Click" />
                Click Element{this.props.action.multi ? 's' : ''}
                <div className="title-options">
                  <div className="title-options__option">
                    <input type="radio" name={`multi_${this.props.index}`} data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} defaultChecked={this.props.action.multi !== true} value="single" id={`single_${this.props.index}`} className="input-radio" />
                    <label htmlFor={`single_${this.props.index}`} className="input-label">Single</label>
                  </div>
                  <div className="title-options__option">
                    <input type="radio" name={`multi_${this.props.index}`} data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} defaultChecked={this.props.action.multi === true} value="multiple" id={`multiple_${this.props.index}`} className="input-radio" />
                    <label htmlFor={`multiple_${this.props.index}`} className="input-label">Multiple</label>
                  </div>
                </div>
                <button type="button" className="custom-action__btn-remove" onClick={() => this.props.removeCustomAction(this.props.index)}>
                  <img src={ xIcon } alt="Remove" />
                </button>
              </div>
              <div className="custom-action__content">
                <label htmlFor="element_to_click" className="input-label input-label--dark">CSS selector or XPath:</label>
                <input type="text" name="selector" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} placeholder="Example: a.toggle-menu" value={this.props.action.selector} id="element_to_click" className="input-field" />
              </div>
            </div>
          </div>
        );
      case "hide":
        return (
          <div className={`custom-action-wrapper ${this.props.index === this.props.draggedItemIndex ? 'custom-action-wrapper--being-dragged' : ''} ${this.props.index === this.props.draggedOverIndex ? 'custom-action-wrapper--dragged-over' : ''} ${this.props.index > this.props.draggedItemIndex ? 'custom-action-wrapper--dragged-below' : 'custom-action-wrapper--dragged-above'}`}>
            <div className="custom-action" draggable onDragStart={this.props.onDragStart} onDragEnter={this.props.onDragEnter} onDragEnd={this.props.onDragEnd}>
              <div className="custom-action__title">
                <img src={ eyeClosedIcon } alt="Eye Closed" />
                Hide Element
                <button type="button" className="custom-action__btn-remove" onClick={() => this.props.removeCustomAction(this.props.index)}>
                  <img src={ xIcon } alt="Remove" />
                </button>
              </div>
              <div className="custom-action__content">
                <label htmlFor="element_to_hide" className="input-label input-label--dark">CSS selector or XPath:</label>
                <input type="text" name="selector" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} placeholder="Example: div.cookie-notification" value={this.props.action.selector} id="element_to_hide" className="input-field" />
              </div>
            </div>
          </div>
        );
      case "add-class":
        return (
          <div className={`custom-action-wrapper ${this.props.index === this.props.draggedItemIndex ? 'custom-action-wrapper--being-dragged' : ''} ${this.props.index === this.props.draggedOverIndex ? 'custom-action-wrapper--dragged-over' : ''} ${this.props.index > this.props.draggedItemIndex ? 'custom-action-wrapper--dragged-below' : 'custom-action-wrapper--dragged-above'}`}>
            <div className="custom-action" draggable onDragStart={this.props.onDragStart} onDragEnter={this.props.onDragEnter} onDragEnd={this.props.onDragEnd}>
              <div className="custom-action__title">
                <img src={ addIcon } alt="Add" />
                Add CSS Class
                <button type="button" className="custom-action__btn-remove" onClick={() => this.props.removeCustomAction(this.props.index)}>
                  <img src={ xIcon } alt="Remove" />
                </button>
              </div>
              <div className="custom-action__content">
                <div className="flex-container">
                  <div className="flex-item flex-item--50 flex-item--static">
                    <label htmlFor="css_classes" className="input-label input-label--dark">Add this class:</label>
                    <input type="text" name="css_classes" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} placeholder="Example: active" value={this.props.action.css_classes} id="css_classes" className="input-field" />
                    <img src={arrowRight} alt="Arrow" className="input-target-arrow" />
                  </div>
                  <div className="flex-item flex-item--50 flex-item--static">
                    <label htmlFor="element_to_add_class_to" className="input-label input-label--dark">To this element:</label>
                    <input type="text" name="selector" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} placeholder="CSS selector or XPath" value={this.props.action.selector} id="element_to_add_class_to" className="input-field" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "remove-class":
        return (
          <div className={`custom-action-wrapper ${this.props.index === this.props.draggedItemIndex ? 'custom-action-wrapper--being-dragged' : ''} ${this.props.index === this.props.draggedOverIndex ? 'custom-action-wrapper--dragged-over' : ''} ${this.props.index > this.props.draggedItemIndex ? 'custom-action-wrapper--dragged-below' : 'custom-action-wrapper--dragged-above'}`}>
            <div className="custom-action" draggable onDragStart={this.props.onDragStart} onDragEnter={this.props.onDragEnter} onDragEnd={this.props.onDragEnd}>
              <div className="custom-action__title">
                <img src={ minusIcon } alt="Minus" />
                Remove CSS Class
                <button type="button" className="custom-action__btn-remove" onClick={() => this.props.removeCustomAction(this.props.index)}>
                  <img src={ xIcon } alt="Remove" />
                </button>
              </div>
              <div className="custom-action__content">
                <div className="flex-container">
                  <div className="flex-item flex-item--50 flex-item--static">
                    <label htmlFor="css_classes" className="input-label input-label--dark">Remove this class:</label>
                    <input type="text" name="css_classes" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} placeholder="Example: active" value={this.props.action.css_classes} id="css_classes" className="input-field" />
                    <img src={arrowRight} alt="Arrow" className="input-target-arrow" />
                  </div>
                  <div className="flex-item flex-item--50 flex-item--static">
                    <label htmlFor="element_to_remove_class_from" className="input-label input-label--dark">From this element:</label>
                    <input type="text" name="selector" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} placeholder="CSS selector or XPath" value={this.props.action.selector} id="element_to_remove_class_from" className="input-field" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "type-into-field":
        return (
          <div className={`custom-action-wrapper ${this.props.index === this.props.draggedItemIndex ? 'custom-action-wrapper--being-dragged' : ''} ${this.props.index === this.props.draggedOverIndex ? 'custom-action-wrapper--dragged-over' : ''} ${this.props.index > this.props.draggedItemIndex ? 'custom-action-wrapper--dragged-below' : 'custom-action-wrapper--dragged-above'}`}>
            <div className="custom-action" draggable onDragStart={this.props.onDragStart} onDragEnter={this.props.onDragEnter} onDragEnd={this.props.onDragEnd}>
              <div className="custom-action__title">
                <img src={ formTextboxIcon } alt="Textbox" />
                Type Into Field
                <button type="button" className="custom-action__btn-remove" onClick={() => this.props.removeCustomAction(this.props.index)}>
                  <img src={ xIcon } alt="Remove" />
                </button>
              </div>
              <div className="custom-action__content">
                <div className="flex-container">
                  
                  <div className="flex-item flex-item--50 flex-item--static">
                    <label htmlFor="field_value" className="input-label input-label--dark">Type this text:</label>
                    <input type="text" name="value" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} value={this.props.action.value} id="field_value" className="input-field" />
                    <img src={arrowRight} alt="Arrow" className="input-target-arrow" />
                  </div>
                  
                  <div className="flex-item flex-item--50 flex-item--static">
                    <label htmlFor="field_to_type_into" className="input-label input-label--dark">Into this field:</label>
                    <input type="text" name="selector" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} placeholder="CSS selector or XPath" value={this.props.action.selector} id="field_to_type_into" className="input-field" />
                  </div>

                  <div className="flex-item">
                    <p className="note">
                      <img src={ lockIcon } alt="Lock" />
                      <span>These fields are <strong>ENCRYPTED</strong> for your privacy.</span>
                    </p>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        );
      case "go-to-url":
        return (
          <div className={`custom-action-wrapper ${this.props.index === this.props.draggedItemIndex ? 'custom-action-wrapper--being-dragged' : ''} ${this.props.index === this.props.draggedOverIndex ? 'custom-action-wrapper--dragged-over' : ''} ${this.props.index > this.props.draggedItemIndex ? 'custom-action-wrapper--dragged-below' : 'custom-action-wrapper--dragged-above'}`}>
            <div className="custom-action" draggable onDragStart={this.props.onDragStart} onDragEnter={this.props.onDragEnter} onDragEnd={this.props.onDragEnd}>
              <div className="custom-action__title">
                <img src={ globeIcon } alt="Globe" />
                Go to URL
                <button type="button" className="custom-action__btn-remove" onClick={() => this.props.removeCustomAction(this.props.index)}>
                  <img src={ xIcon } alt="Remove" />
                </button>
              </div>
              <div className="custom-action__content">
                <label htmlFor="url_to_go_to" className="input-label input-label--dark">Enter a URL:</label>
                <input type="text" name="url" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} value={this.props.action.url} id="url_to_go_to" className="input-field" />
              </div>
            </div>
          </div>
        );
      case "timed-wait":
        return (
          <div className={`custom-action-wrapper ${this.props.index === this.props.draggedItemIndex ? 'custom-action-wrapper--being-dragged' : ''} ${this.props.index === this.props.draggedOverIndex ? 'custom-action-wrapper--dragged-over' : ''} ${this.props.index > this.props.draggedItemIndex ? 'custom-action-wrapper--dragged-below' : 'custom-action-wrapper--dragged-above'}`}>
            <div className="custom-action" draggable onDragStart={this.props.onDragStart} onDragEnter={this.props.onDragEnter} onDragEnd={this.props.onDragEnd}>
              <div className="custom-action__title">
                <img src={ timerIcon } alt="Timer" />
                Timed Wait
                <button type="button" className="custom-action__btn-remove" onClick={() => this.props.removeCustomAction(this.props.index)}>
                  <img src={ xIcon } alt="Remove" />
                </button>
              </div>
              <div className="custom-action__content">
                <div className="flex-container">
                  <div className="flex-item">
                    <label htmlFor="wait_time" className="input-label input-label--dark">Wait for images to load, animations to finish, and more.</label>
                    <div className="select-wrapper">
                      <select name="wait_time" id="wait_time" className="input-field" data-index={this.props.index} onChange={this.props.handleCustomActionInputChange} value={this.props.action.wait_time}>
                        <option value="0.5">0.5 seconds</option>
                        <option value="1">1 seconds</option>
                        <option value="1.5">1.5 seconds</option>
                        <option value="2">2 seconds</option>
                        <option value="2.5">2.5 seconds</option>
                        <option value="3">3 seconds</option>
                        <option value="4">4 seconds</option>
                        <option value="5">5 seconds</option>
                        <option value="6">6 seconds</option>
                        <option value="7">7 seconds</option>
                        <option value="8">8 seconds</option>
                        <option value="9">9 seconds</option>
                        <option value="10">10 seconds</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
}

export default CustomActionItem;