import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  transformRequest: [function (data, headers) {
    headers["AccessToken"] = localStorage.getItem("access_token");
    headers["RefreshToken"] = localStorage.getItem("refresh_token");
    headers["Content-Type"] = "application/json";

    // I'm not sure why, but sometimes this data object is coming back already
    // stringified, so we have to make sure we don't stringify it again.
    if(typeof data !== "string") {
      data = JSON.stringify(data);
    }
    
    return data;
  }]
});

// Setup interceptors for expired AccessTokens
http.interceptors.response.use(null, (error) => {
  if(error.config && error.response && error.response.status === 401) {
    return http.get("/user/auth/").then((resp) => {
      localStorage.setItem("access_token", resp.data.new_access_token);
      error.config.headers['AccessToken'] = resp.data.new_access_token;
      return axios.request(error.config);
    }).catch((error) => {

      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("plan");

      if(error.response.data && error.response.data.error === "trial_ended") {
        window.location = `/subscribe/${error.response.data.user_id}/${error.response.data.trial_key}`;
      } else {
        window.location = "/login";
      }
      
    });
  }
  return Promise.reject(error);
});

const getErrorMessage = (error) => {
  let resp = "";

  try {
    resp = error.response.data.message;
  }
  catch(error) {
    resp = "Sorry, something went wrong. Please try again later.";
  }

  return resp;
};

export default http;
export { getErrorMessage };