import React from "react";
import { withRouter } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";
import checkForm from "../../utility/validator";

// UI
import SubNav, { SubNavText, SubNavLink, SubNavSep } from "../../ui/SubNav";
import Panel, { PanelTitle } from "../../ui/Panel";
import Loading from "../../ui/Loading";

class UpdateCard extends React.Component {

  constructor() {
    super();

    this.timers = [];
    this.submit = this.submit.bind(this);
    this.handleCardInputChange = this.handleCardInputChange.bind(this);

    this.state = {
      data: {
        card: {
          id: "",
          number: "",
          exp_month: "",
          exp_year: "",
          cvc: ""
        }
      },
      loading: true
    };
  }
  
  componentDidMount() {
    this.props.setSection("settings");

    // Stripe setup
    window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_PUB_KEY);

    // Get the user's info
    http.get("/user/").then(resp => {

      if(resp.data.plan === "trial" || !resp.data.card) {
        this.props.history.push("/settings");
      } else {
        this.setState({ loading: false });
      }
      
    }).catch((error) => {
      console.error(error);
    });
  }

  componentWillUnmount() {
    this.timers.forEach(tm => clearTimeout(tm));
  }

  handleCardInputChange({target}) {
    const copyOfDataObject = { ...this.state.data };
    copyOfDataObject.card[target.name] = target.value;
    this.setState({ data: copyOfDataObject });
  }

  formShowSuccess(text) {
    this.setState({ success: text });
    
    this.timers[this.timers.length] = setTimeout(() => {
      this.setState({ successVisible: true });
      
      this.timers[this.timers.length] = setTimeout(() => {
        this.props.history.push("/settings");
      }, 2000);

    }, 10);
  }
  
  formSuccess() {
    return this.state.success ? <div className={`form-success ${this.state.successVisible ? 'form-success--visible' : ''}`}>{this.state.success}</div> : null;
  }

  formError() {
    return this.state.error ? <div className="form-error">{this.state.error}</div> : null;
  }

  submit(event) {
    this.setState({ error: "" });
    document.activeElement.blur();

    if(checkForm(event.target)) {
      this.setState({ loading: true });

      window.Stripe.createToken(event.target, (resp, data) => {
        
        if(data.error) {
          this.setState({ error: data.error.message, loading: false });
        } else {
          
          const copyOfData = {...this.state.data};
          copyOfData.stripe_token = data.id;
          
          copyOfData.card = {
            id: data.card.id,
            exp_month: data.card.exp_month,
            exp_year: data.card.exp_year,
            last4: data.card.last4,
            brand: data.card.brand
          };

          this.setState({ data: copyOfData });
          this.completeCardUpdate();
        }
      });
    }
    
    event.preventDefault();
  }

  completeCardUpdate() {
    http.put("/user/update-card/", this.state.data).then(resp => {
      this.setState({ loading: false });
      document.getElementById("update_card_form").reset();
      this.formShowSuccess("Your card has been updated!");
    }).catch((error) => {
      this.setState({ loading: false, error: getErrorMessage(error) });
    });
  }
  
  render() {

    // Year Options
    const YearOptions = () => {
      let year = new Date().getFullYear();
      let years = [];
      
      for(let i = 0; i < 21; i++) {
        years.push(<option key={i} value={year + i}>{year + i}</option>);
      }

      return years;
    };
    
    return (
      <div>

        <SubNav>
          <SubNavLink to="/settings">Settings</SubNavLink>
          <SubNavSep />
          <SubNavText>Update Card</SubNavText>
        </SubNav>

        {/* UPDATE CARD FORM */}
        <Panel semiWide hasTitle>

          <PanelTitle>Update Credit/Debit Card</PanelTitle>

          <form id="update_card_form" name="update_card_form" onSubmit={this.submit} noValidate className={`${this.state.loading ? 'loading' : ''}`}>
            <div className="flex-container">

              <div className="flex-item">
                <input type="hidden" name="stripe_name" data-stripe="name" value={`${this.state.data.first_name} ${this.state.data.last_name}`} className="input-field" />
                <label htmlFor="number" className="input-label">Credit Card #</label>
                <input type="text" name="number" autoFocus minLength="10" size="20" maxLength="20" onChange={this.handleCardInputChange} defaultValue={this.state.data.card.number} id="number" data-stripe="number" className="input-field" required />
              </div>

              <div className="flex-item flex-item--card-exp-date">
                <label htmlFor="exp_month" className="input-label">Card Expiration</label>

                <div className="select-wrapper select-wrapper--super-short">
                  <select name="exp_month" id="exp_month" className="input-field" onChange={this.handleCardInputChange} defaultValue={this.state.data.card.exp_month} data-stripe="exp-month" required>
                    <option value="">MM</option>
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                    <option value="4">04</option>
                    <option value="5">05</option>
                    <option value="6">06</option>
                    <option value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>

                <div className="select-wrapper select-wrapper--short">
                  <select name="exp_year" id="exp_year" className="input-field" onChange={this.handleCardInputChange} value={this.state.data.card.exp_year} data-stripe="exp-year" required>
                    <option value="">YYYY</option>
                    <YearOptions/>
                  </select>
                </div>
                
              </div>

              <div className="flex-item flex-item--card-cvc">
                <label htmlFor="email" className="input-label">CVC</label>
                <input type="text" name="cvc" onChange={this.handleCardInputChange} defaultValue={this.state.data.card.cvc} id="cvc" minLength="3" size="4" maxLength="4" data-stripe="cvc" className="input-field input-field--short" required />
              </div>

              {this.formSuccess()}
              {this.formError()}

              <div className="flex-item centered">
                <input type="submit" value="Update Card" className="btn btn--wide btn--push-top" />
              </div>

            </div>
          </form>

          <Loading loading={this.state.loading} />

        </Panel>

        <div className="chat-widget-breathing-room"></div>
        
      </div>
    );
  }
}

export default withRouter(UpdateCard);