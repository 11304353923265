import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import { withRouter } from "react-router-dom";
import http from "../../utility/http";

// UI
import arrowLeft from "../../ui/img/key-arrow-left.svg";
import arrowRight from "../../ui/img/key-arrow-right.svg";
import Loading from "../../ui/Loading";

class ScreenshotViewer extends React.Component {
  
  constructor(props) {
    super(props);

    this.getScreenshotUrls = this.getScreenshotUrls.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.prevScreenshot = this.prevScreenshot.bind(this);
    this.nextScreenshot = this.nextScreenshot.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.viewScreenshot = this.viewScreenshot.bind(this);
    this.downloadScreenshot = this.downloadScreenshot.bind(this);

    this.state = {
      loading: false,
      activeScreenshotIndex: 0,
      activeScreenshotUrl: '',
      accessToken: localStorage.getItem("access_token")
    };
  }

  componentDidMount() {

    // Load the first screenshot
    this.getScreenshotUrls((data) => {
      this.setState({
        activeScreenshotUrl: data.view_url
      })
    });

    // Setup keyboard shortcuts
    document.addEventListener("keydown", this.handleKeydown, false);
  }

  componentWillUnmount() {

    // Unbind keyboard shortcuts
    document.removeEventListener("keydown", this.handleKeydown, false);
  }

  handleKeydown(e) {
    
    // Don't override back button shortcuts
    if(e.metaKey === false && e.altKey === false) {

      // Let the left/right arrow keys navigate between screenshots
      if(e.keyCode === 39) {
        this.nextScreenshot(e);
      } else if(e.keyCode === 37) {
        this.prevScreenshot(e);
      }
    }
  }

  getScreenshotUrls(cb, newIndex=this.state.activeScreenshotIndex) {
    http.get(`/screenshot/get-urls/${this.props.page.screenshots[newIndex].id}/`).then(resp => {
      cb(resp.data);
    });
  }

  prevScreenshot(e) {

    if(this.state.activeScreenshotIndex > 0) {
      const newIndex = this.state.activeScreenshotIndex - 1;

      this.setState({ loading: true });

      this.getScreenshotUrls((data) => {
        setTimeout(() => {
          this.setState({
            activeScreenshotIndex: newIndex,
            activeScreenshotUrl: data.view_url
          });
        }, 50);
      }, newIndex);
    }
    
    e.preventDefault();
  }
  
  nextScreenshot(e) {

    if(this.state.activeScreenshotIndex < (this.props.page.screenshots.length - 1)) {
      const newIndex = this.state.activeScreenshotIndex + 1;
      this.setState({ loading: true });

      this.getScreenshotUrls((data) => {
        setTimeout(() => {
          this.setState({
            activeScreenshotIndex: newIndex,
            activeScreenshotUrl: data.view_url
          });
        }, 50);
      }, newIndex);
    }
    
    e.preventDefault();
  }

  handleImageLoaded() {
    this.setState({
      loading: false
    });
  }

  viewScreenshot() {
    this.getScreenshotUrls((data) => {
      window.open(data.view_url);
    });
  }

  downloadScreenshot() {
    this.getScreenshotUrls((data) => {
      window.open(`${data.download_url}?ts=${new Date().valueOf()}`);
    });
  }
  
  render() {

    const calendarStrings = {
      lastDay: "MMM D, YYYY @ h:mm A",
      sameDay: "MMM D, YYYY @ h:mm A",
      nextDay: "MMM D, YYYY @ h:mm A",
      lastWeek: "MMM D, YYYY @ h:mm A",
      nextWeek: "MMM D, YYYY @ h:mm A",
      sameElse: "MMM D, YYYY @ h:mm A"
    };
    
    return (
      <div className="screenshot-wrapper">

        <div className="screenshot-detail-header">

          <h3>
            Captured on <Moment calendar={calendarStrings} interval={0}>{this.props.page.screenshots[this.state.activeScreenshotIndex].date_created.$date}</Moment>
            <button type="button" className="btn btn--secondary btn--sm" onClick={this.viewScreenshot}>View Full-Size</button>
            <button type="button" className="btn btn--sm" onClick={this.downloadScreenshot}>Download Image</button>
          </h3>

          <div className="screenshot-nav">
          
            <button className="btn btn--secondary btn--sm btn--left" title="Press &larr; on keyboard" onClick={this.prevScreenshot}>
              <img src={arrowLeft} alt="Prev" />
            </button>
            
            <span className="screenshot-nav__count">
              {this.state.activeScreenshotIndex + 1} of {this.props.page.total_capture_count}
            </span>

            <button className="btn btn--secondary btn--sm btn--right" title="Press &rarr; on keyboard" onClick={this.nextScreenshot}>
              <img src={arrowRight} alt="Next" />
            </button>
            
          </div>

        </div>

        <div className={`screenshot-viewer ${this.state.loading ? 'screenshot-viewer--loading' : ''}`}>
          <Loading loading={this.state.loading} />
          <img src={this.state.activeScreenshotUrl} alt={this.props.page.name} className="screenshot" onLoad={this.handleImageLoaded} />
        </div>

      </div>
    );
  }
}

export default withRouter(ScreenshotViewer);