import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// UI
import SubNav, { SubNavText, SubNavLink, SubNavSep } from "../../ui/SubNav";
import Panel, { PanelTitle } from "../../ui/Panel";
import arrowRight from "../../ui/img/arrow-right.svg";

const DropboxConnected = (props) => {

  useEffect(() => {
    props.setSection("settings");
  }, []);
  
  return (
    <div>

      <SubNav>
        <SubNavLink to="/settings">Settings</SubNavLink>
        <SubNavSep />
        <SubNavText>Dropbox Connected</SubNavText>
      </SubNav>

      <Panel wide spaced hasTitle centered>

        <PanelTitle>Dropbox Connected</PanelTitle>

        <h2 className="dark">We created a new folder in your Dropbox account:</h2>

        <p>
          <strong className="semibold">Dropbox <img src={arrowRight} alt="Arrow" className="dir-path-sep" /> Apps <img src={arrowRight} alt="Arrow" className="dir-path-sep" /> Screenlapse.com</strong>
        </p>

        <p>
          All screenshots will be automatically synced to this folder.
          <br/>
          You can change this at any time in <Link to="/settings">Settings</Link>.
        </p>

      </Panel>

    </div>
  );
};

export default DropboxConnected;