import React from "react";
import http from "../../utility/http";

// UI
import Panel, { PanelTitle, PanelSection } from "../../ui/Panel";
import arrowRight from "../../ui/img/arrow-right.svg";
import Loading from "../../ui/Loading";

const SyncAppsPanel = (props) => {

  const beginDropboxOauth = () => {
    http.get("/dropbox/begin-oauth").then(resp => {
      window.location.replace(resp.data);
    });
  };
  
  const disconnectDropbox = () => {
    http.get("/dropbox/disconnect/").then(resp => {
      const copyOfUserData = {...props.user};
      delete copyOfUserData.dropbox;
      props.updateUserData(copyOfUserData);
    });
  };
  
  const Content = () => {
    if(props.user.dropbox) {
      return (
        <PanelSection
          cta={<button className="btn btn--sm btn--secondary" onClick={disconnectDropbox}>Disconnect</button>}
          loading={props.loadingUserData}
        >
          <h3>Dropbox: Connected</h3>
          <p>
            All screenshots are being saved to this folder:&nbsp;
            <strong className="semibold">Dropbox <img src={arrowRight} alt="Arrow" className="dir-path-sep" /> Apps <img src={arrowRight} alt="Arrow" className="dir-path-sep" /> Screenlapse.com</strong>
          </p>
        </PanelSection>
      );
    } else {
      return (
        <PanelSection
          cta={<button className="btn btn--sm" onClick={beginDropboxOauth}>Connect</button>}
          loading={props.loadingUserData}
        >
          <h3>Dropbox</h3>
          <p>Save all screenshots to your Dropbox account</p>
        </PanelSection>
      );
    }
  }

  return (
    <Panel wide spaced hasTitle noPadding>

      <PanelTitle>Sync Apps</PanelTitle>

      <Content/>

      <Loading loading={props.loadingUserData} />
    
    </Panel>
  );
};

export default SyncAppsPanel;