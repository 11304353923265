import React from "react";
import Twemoji from "react-twemoji";

const emojiAttributes = (props) => {
  return {
    role: "img",
    "aria-label": props.label || "emoji"
  }
}

const Emoji = (props) => {
  return (
    <Twemoji tag="span" className="emoji-wrapper" options={{ attributes: () => emojiAttributes(props) }}>{props.children}</Twemoji>
  );
}

export default Emoji;