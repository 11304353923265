import React from "react";
import { withRouter } from "react-router-dom";
import http from "../utility/http";

// UI
import Panel from "../ui/Panel";
import Loading from "../ui/Loading";

class Logout extends React.Component {

  componentDidMount() {

    this.props.setSection("logout");

    http.get("/user/logout/").then(resp => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("plan");
      this.props.history.push("/login");
    }).catch(err => {
      console.error(err);
    });
  }

  render() {
    return (
      <Panel spaced>
        <Loading loading={true} />
      </Panel>
    );
  }
}

export default withRouter(Logout);