import React, { Component } from "react";

const PanelImage = (props) => {
  
  let backgroundStyles = {};
  
  if(props.src) {
    backgroundStyles = {
      backgroundImage: `url(${props.src})`
    };
  }
  
  return (
    <div className="panel-image" style={backgroundStyles}>{props.children}</div>
  );
}

const PanelTitle = (props) => <h1 className={`panel-title ${props.leftAlign ? 'panel-title--la' : ''}`}>{props.children}</h1>;
const PanelSubtitle = (props) => <h2 className="panel-subtitle">{props.children}</h2>;
const PanelMetadata = (props) => <div className="panel-metadata">{props.children}</div>;

const PanelSection = (props) => {
  if(props.loading) {
    return null;
  }
  return (
    <div className={`panel-section ${!props.cta ? 'panel-section--no-cta' : ''}`}>
      <div className="panel-section__content">
        {props.children}
      </div>
      <div className="panel-section__cta">
        {props.cta}
      </div>
    </div>
  );
};

class Panel extends Component {
  render() {
    if(this.props.link) {
      return (
        <a href={this.props.link} className={`panel ${this.props.lessMarginBottom ? 'panel--less-mb' : ''} ${this.props.error ? 'panel--error' : ''} ${this.props.compact ? 'panel--compact' : ''} ${this.props.noPadding ? 'panel--no-padding' : ''} ${this.props.link ? 'panel--link' : ''} ${this.props.minimal ? 'panel--minimal' : ''} ${this.props.spaced ? 'panel--spaced' : ''} ${this.props.wide ? 'panel--wide' : ''} ${this.props.semiWide ? 'panel--semi-wide' : ''} ${this.props.hasTitle ? 'panel--has-title' : ''} ${this.props.mobileNoMargin ? 'panel--mobile-no-margin' : ''} ${this.props.centered ? 'centered' : ''} ${this.props.className}`}>
          {this.props.children}
        </a>
      );
    } else {
      return (
        <div className={`panel ${this.props.lessMarginBottom ? 'panel--less-mb' : ''} ${this.props.error ? 'panel--error' : ''} ${this.props.compact ? 'panel--compact' : ''} ${this.props.noPadding ? 'panel--no-padding' : ''} ${this.props.minimal ? 'panel--minimal' : ''} ${this.props.spaced ? 'panel--spaced' : ''} ${this.props.wide ? 'panel--wide' : ''} ${this.props.semiWide ? 'panel--semi-wide' : ''} ${this.props.hasTitle ? 'panel--has-title' : ''} ${this.props.mobileNoMargin ? 'panel--mobile-no-margin' : ''} ${this.props.centered ? 'centered' : ''} ${this.props.className}`}>
          {this.props.children}
        </div>
      );
    }
  }
}

export default Panel;
export { PanelTitle, PanelSubtitle, PanelImage, PanelMetadata, PanelSection };