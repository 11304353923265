import React from "react";

const ErrorCard = (props) => {
  return (
    <div className="error-card">
      <h4 className="error-card__title">
        <span>{props.error.name}</span>
        <span className="error-card__error">{props.error.error}</span>
      </h4>
      <p className="error-card__content">{props.error.description}</p>
    </div>
  );
}

export default ErrorCard;