import React, { Component } from "react";

class ToggleSwitch extends Component {

  constructor(props) {
    super(props);

    this.toggleSwitch = this.toggleSwitch.bind(this);

    this.state = {
      active: this.props.active
    };
  }
  
  toggleSwitch(e) {
    const newActiveState = !this.state.active;
    this.setState({ active: newActiveState });

    // Account for the switch's CSS transition time. Wait for the animation to
    // complete before updating the parent component's state (which causes a
    // flash and kills the animation otherwise).
    setTimeout(() => {
      this.props.callback(newActiveState);
    }, 300);
    
    e.preventDefault();
  }
  
  render() {
    
    return (
      <span className={`toggle-switch ${this.state.active ? 'toggle-switch--active': ''}`} onClick={this.toggleSwitch}>
        <span className="toggle-switch__label">
          <span className="toggle-switch__label-text-active">{this.props.activeLabel ? this.props.activeLabel : 'Active'}</span>
          <span className="toggle-switch__label-text-inactive">{this.props.inactiveLabel ? this.props.inactiveLabel : 'Inactive'}</span>
        </span>
        <span className="toggle-switch__handle"></span>
      </span>
    );
  }
}

export default ToggleSwitch;