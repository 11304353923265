import React, { Component } from "react";

class CountryOptions extends Component {
  render() {

    const countries = [
      { value: "gate", label: "Random - A random country with each request" },
      { value: "us", label: "United States of America" },
      { value: "ca", label: "Canada" },
      { value: "gb", label: "Great Britain" },
      { value: "de", label: "Germany" },
      { value: "fr", label: "France" },
      { value: "es", label: "Spain" },
      { value: "it", label: "Italy" },
      { value: "se", label: "Sweden" },
      { value: "gr", label: "Greece" },
      { value: "pt", label: "Portugal" },
      { value: "nl", label: "Netherlands" },
      { value: "be", label: "Belgium" },
      { value: "ru", label: "Russia" },
      { value: "ua", label: "Ukraine" },
      { value: "pl", label: "Poland" },
      { value: "il", label: "Israel" },
      { value: "tr", label: "Turkey" },
      { value: "au", label: "Australia" },
      { value: "my", label: "Malaysia" },
      { value: "th", label: "Thailand" },
      { value: "kr", label: "South Korea" },
      { value: "jp", label: "Japan" },
      { value: "ph", label: "Philippines" },
      { value: "sg", label: "Singapore" },
      { value: "cn", label: "China" },
      { value: "hk", label: "Hong Kong" },
      { value: "tw", label: "Taiwan" },
      { value: "in", label: "India" },
      { value: "pk", label: "Pakistan" },
      { value: "ir", label: "Iran" },
      { value: "id", label: "Indonesia" },
      { value: "az", label: "Azerbaijan" },
      { value: "kz", label: "Kazakhstan" },
      { value: "ae", label: "United Arab Emirates" },
      { value: "mx", label: "Mexico" },
      { value: "br", label: "Brazil" },
      { value: "ar", label: "Argentina" },
      { value: "cl", label: "Chile" },
      { value: "pe", label: "Peru" },
      { value: "ec", label: "Ecuador" },
      { value: "co", label: "Colombia" },
      { value: "za", label: "South Africa" },
      { value: "eg", label: "Egypt" },
      { value: "ao", label: "Angola" },
      { value: "cm", label: "Cameroon" },
      { value: "cf", label: "Central African Republic" },
      { value: "td", label: "Chad" },
      { value: "bj", label: "Benin" },
      { value: "et", label: "Ethiopia" },
      { value: "dj", label: "Djibouti" },
      { value: "gm", label: "Gambia" },
      { value: "gh", label: "Ghana" },
      { value: "ke", label: "Kenya" },
      { value: "lr", label: "Liberia" },
      { value: "mg", label: "Madagascar" },
      { value: "ml", label: "Mali" },
      { value: "mr", label: "Mauritania" },
      { value: "mu", label: "Mauritius" },
      { value: "ma", label: "Morocco" },
      { value: "mz", label: "Mozambique" },
      { value: "ng", label: "Nigeria" },
      { value: "sn", label: "Senegal" },
      { value: "sc", label: "Seychelles" },
      { value: "zw", label: "Zimbabwe" },
      { value: "ss", label: "South Sudan" },
      { value: "sd", label: "Sudan" },
      { value: "tg", label: "Togo" },
      { value: "tn", label: "Tunisia" },
      { value: "ug", label: "Uganda" },
      { value: "zm", label: "Zambia" },
      { value: "af", label: "Afghanistan" },
      { value: "bh", label: "Bahrain" },
      { value: "bd", label: "Bangladesh" },
      { value: "bt", label: "Bhutan" },
      { value: "mm", label: "Myanmar" },
      { value: "kh", label: "Cambodia" },
      { value: "iq", label: "Iraq" },
      { value: "jo", label: "Jordan" },
      { value: "lb", label: "Lebanon" },
      { value: "mv", label: "Maldives" },
      { value: "mn", label: "Mongolia" },
      { value: "om", label: "Oman" },
      { value: "qa", label: "Qatar" },
      { value: "sa", label: "Saudi Arabia" },
      { value: "tm", label: "Turkmenistan" },
      { value: "uz", label: "Uzbekistan" },
      { value: "ye", label: "Yemen" },
      { value: "al", label: "Albania" },
      { value: "ad", label: "Andorra" },
      { value: "at", label: "Austria" },
      { value: "am", label: "Armenia" },
      { value: "ba", label: "Bosnia and Herzegovina" },
      { value: "bg", label: "Bulgaria" },
      { value: "by", label: "Belarus" },
      { value: "hr", label: "Croatia" },
      { value: "cy", label: "Cyprus" },
      { value: "cz", label: "Czech Republic" },
      { value: "dk", label: "Denmark" },
      { value: "ee", label: "Estonia" },
      { value: "fi", label: "Finland" },
      { value: "ge", label: "Georgia" },
      { value: "hu", label: "Hungary" },
      { value: "is", label: "Iceland" },
      { value: "ie", label: "Ireland" },
      { value: "lv", label: "Latvia" },
      { value: "li", label: "Liechtenstein" },
      { value: "lt", label: "Lithuania" },
      { value: "lu", label: "Luxembourg" },
      { value: "mc", label: "Monaco" },
      { value: "md", label: "Moldova" },
      { value: "me", label: "Montenegro" },
      { value: "no", label: "Norway" },
      { value: "ro", label: "Romania" },
      { value: "rs", label: "Serbia" },
      { value: "sk", label: "Slovakia" },
      { value: "si", label: "Slovenia" },
      { value: "ch", label: "Switzerland" },
      { value: "mk", label: "Macedonia" },
      { value: "bs", label: "Bahamas" },
      { value: "bz", label: "Belize" },
      { value: "vg", label: "British Virgin Islands" },
      { value: "cr", label: "Costa Rica" },
      { value: "cu", label: "Cuba" },
      { value: "dm", label: "Dominica" },
      { value: "ht", label: "Haiti" },
      { value: "hn", label: "Honduras" },
      { value: "jm", label: "Jamaica" },
      { value: "aw", label: "Aruba" },
      { value: "pa", label: "Panama" },
      { value: "pr", label: "Puerto Rico" },
      { value: "tt", label: "Trinidad and Tobago" },
      { value: "fj", label: "Fiji" },
      { value: "nz", label: "New Zealand" },
      { value: "bo", label: "Bolivia" },
      { value: "py", label: "Paraguay" },
      { value: "uy", label: "Uruguay" },
      { value: "ci", label: "Côte d'Ivoire" },
      { value: "sy", label: "Syria" },
      { value: "vn", label: "Vietnam" }
    ];

    // Alphabetically sort the countries
    countries.sort((a, b) => (a.label > b.label || b.label === 'Random - A random country with each request') ? 1 : -1);

    return countries.map((country) => <option key={country.value} value={country.value}>{country.label}</option>);
  }
}

export default CountryOptions;