import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import { withRouter, Link } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";

// UI
import SubNav, { SubNavText, SubNavLink, SubNavSep, SubNavButton } from "../../ui/SubNav";
import NoResults from "../../ui/NoResults";
import Loading from "../../ui/Loading";
import Panel, { PanelTitle } from "../../ui/Panel";
import ScreenshotViewer from "./ScreenshotViewer";
import NoScreenshots from "./NoScreenshots";
import ToggleSwitch from "../../ui/ToggleSwitch";
import PageFrequency from "./PageFrequency";
import NextScreenshotInfo from "./NextScreenshotInfo";
import ErrorCard from "../../ui/ErrorCard";

class PageDetail extends React.Component {
  
  constructor() {
    super();
    
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.downloadAllScreenshots = this.downloadAllScreenshots.bind(this);

    this.state = {
      loading: true,
      getInverval: null
    };
  }

  componentWillMount() {
    
    // Set the user's timezone so Moment.js dates are correctly formatted
    const timezone = localStorage.getItem("timezone");

    if(timezone) {
      Moment.globalTimezone = timezone;
    }
  }
  
  componentDidMount() {
    this.props.setSection("screenshots");

    this.getPage();
  }

  componentWillUnmount() {
    clearInterval(this.state.getInverval);
  }
  
  getPage() {

    // Get this page
    http.get(`/page/${this.props.match.params.id}/`).then(resp => {
      this.setState({ loading: false, page: resp.data });

      // If the Page doesn't have it's initial screenshot yet, reload the Page
      // every so often until it does. This prevents the user from having to
      // manually refresh the page to check.
      if(!this.state.getInverval && resp.data.total_capture_count === 0) {
        
        this.setState({
          getInverval: setInterval(() => this.getPage(), 15000)
        });
        
      } else if(this.state.getInverval && resp.data.total_capture_count > 0) {
        clearInterval(this.state.getInverval);
      }
      
    }).catch((error) => {
      this.setState({ loading: false, error: getErrorMessage(error) });
    });
  }

  toggleActiveStatus(newActiveState) {
    
    // Save the new active status
    http.put(`/page/change-status/${this.state.page.id}/`, { active: newActiveState }).then(resp => {
      const copyOfPage = { ...this.state.page };
      copyOfPage.active = newActiveState;

      if(resp.data.next_capture_date) {
        copyOfPage.next_capture_date = resp.data.next_capture_date;
      }
      
      this.setState({ page: copyOfPage });
    });
  }

  downloadAllScreenshots(e) {
    this.setState({ downloadPreparing: true });
    
    // Create a zipfile containing all of this page's screenshots
    http.get(`/screenshot/create-zipfile/${this.state.page.id}/`).then(resp => {
      if(resp.data.download_filename && resp.data.user_id) {
        const zipfileDownloadUrl = `${process.env.REACT_APP_API_HOST}/screenshot/download-zipfile/${resp.data.user_id}/${this.state.page.id}/${resp.data.download_filename}?ts=${new Date().valueOf()}`;
        this.setState({ downloadPreparing: false });
        window.open(zipfileDownloadUrl);
      }
    }).catch((error) => {
      alert(error.response.data.message);
      this.setState({ downloadPreparing: false });
    });
    
    e.preventDefault();
  }
  
  render() {

    const frequencyStrings = {
      hourly: "Hourly",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly"
    };

    let breadcrumbText = "";
    let Body = () => <Loading loading={this.state.loading} />;

    if(this.state.page) {
      breadcrumbText = this.state.page.name;
      
      Body = () => {
        return (
          <div className="page-detail-wrapper">
            <div className="flex-container">

              <div className="flex-item flex-item--25">
              
                {/* Proxy Limit Exception */}
                { this.state.page.proxy_limit_exception ? <Panel hasTitle compact error><PanelTitle>Proxy Bandwidth Limit</PanelTitle><p><strong>Screenshots paused:</strong> You have used all of your proxy bandwidth for the month. <Link to="/settings">Upgrade Now</Link></p></Panel> : '' }

                {/* Screenshot Errors */}
                { this.state.page.errors && this.state.page.errors.length ? this.state.page.errors.map((err, idx) => <ErrorCard error={err} key={idx} /> ) : '' }
              
                <Panel>

                  <p>
                    <label className="input-label input-label--dark">Page URL</label>
                    <a href={this.state.page.url} target="_blank" rel="noopener noreferrer">{this.state.page.url}</a>
                  </p>

                  <p>
                    <label className="input-label input-label--dark">Screenshot Frequency</label>
                    {frequencyStrings[this.state.page.frequency]}: <PageFrequency data={this.state.page} />
                  </p>

                  <p>
                    <label className="input-label input-label--dark">Next Screenshot</label>
                    <NextScreenshotInfo data={this.state.page} />
                  </p>

                  <p>
                    <label className="input-label input-label--dark">Status</label>
                    <ToggleSwitch active={this.state.page.active} callback={this.toggleActiveStatus} />
                  </p>

                  <p>
                    <label className="input-label input-label--dark">Date Created</label>
                    <Moment format="MMM D, YYYY" interval={0}>{this.state.page.date_created.$date}</Moment>
                  </p>

                </Panel>

                <div className="centered">
                  {this.state.page.screenshots.length ? <button className={`btn btn--secondary btn--full-width ${this.state.downloadPreparing ? 'btn--loading' : ''}`} onClick={this.downloadAllScreenshots}>{this.state.downloadPreparing ? 'Preparing...' : 'Download all Screenshots'}</button> : null}
                </div>
                
              </div>

              <div className="flex-item flex-item--75">
                {this.state.page.screenshots.length ? <ScreenshotViewer page={this.state.page} /> : <NoScreenshots/>}
              </div>

            </div>
          </div>
        );
      };

    } else if(!this.state.page && !this.state.loading) {

      breadcrumbText = "Page not found";

      Body = () => {
        return (
          <div>
            
            <NoResults>
              <p>This page doesn't appear to exist.</p>
              <Link to="/" className="btn">View all pages</Link>
            </NoResults>
          
          </div>
        );
      };
      
    }
    
    return (
      <div>

        <SubNav>
          <SubNavLink to="/">Pages</SubNavLink>
          <SubNavSep />
          <SubNavText>{breadcrumbText}</SubNavText>
          <SubNavButton to={`/page/${this.props.match.params.id}/edit`} secondary>Page Settings</SubNavButton>
        </SubNav>
        
        <Body />
        
        <div className="chat-widget-breathing-room"></div>
        
      </div>
    );
  }
}

export default withRouter(PageDetail);