import React from "react";
import { Link } from "react-router-dom";

// UI
import xIcon from "../../ui/img/x.svg";

class Notification extends React.Component {
  render() {

    if(!this.props.data) {
      return null;
    }

    return (
      <div className="notification">
        
        {this.props.data.link ? <Link to={this.props.data.link} className="notification__text">{this.props.data.text}</Link> : null}
        {!this.props.data.link ? <span className="notification__text">{this.props.data.text}</span> : null}

        <button type="button" className="notification__dismiss">
          <img src={xIcon} alt="Dismiss" onClick={() => this.props.data.dismiss()} />
        </button>

      </div>
    );
  }
}

export default Notification;