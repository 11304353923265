import React from "react";

// Icons
import xIcon from "../../ui/img/x.svg";

class CookieItem extends React.Component {
  render() {

    const ph_text = () => {
      switch(this.props.cookie.type) {
        case 'cookie':
          return 'Value (cookie)';
        case 'local_storage':
          return 'Value (local storage)';
        case 'session_storage':
          return 'Value (session storage)';
        default:
          return 'Value (cookie)';
      }
    };

    return (
      <div className="cookie-item">
        <button type="button" className="cookie__btn-remove" onClick={() => this.props.removeCookie(this.props.index)}>
          <img src={ xIcon } alt="Remove" />
        </button>
        <div className="flex-container flex-container--tighter">
          <div className="flex-item flex-item--30">
            <input type="text" name="name" data-index={this.props.index} onChange={this.props.handleCookieInputChange} placeholder="Name" value={this.props.cookie.name} id="cookie_name" className="input-field" />
          </div>
          <div className="flex-item flex-item--70">
            <input type="text" name="value" data-index={this.props.index} onChange={this.props.handleCookieInputChange} placeholder={ph_text()} value={this.props.cookie.value} id="cookie_value" className="input-field" />
          </div>
        </div>
      </div>
    );
  }
}

export default CookieItem;