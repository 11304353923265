import React from "react";

const hoursOfTheDay = [
  "12:00 AM",
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM"
];

const daysOfTheWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

const PageFrequency = (props) => {

  if(props.data.frequency === "hourly") {
    return (
      <span>Every hour on the hour</span>
    );
  }

  if(props.data.frequency === "daily") {
    return (
      <span>Every day at {hoursOfTheDay[props.data.frequency_settings.hour_of_day]}</span>
    );
  }

  if(props.data.frequency === "weekly") {
    return (
      <span>Every {daysOfTheWeek[props.data.frequency_settings.day_of_week]} at {hoursOfTheDay[props.data.frequency_settings.hour_of_day]}</span>
    );
  }

  if(props.data.frequency === "monthly") {

    let dayOfTheMonth = "";

    if(props.data.frequency_settings.day_of_month === "first") {
      dayOfTheMonth = "On the first day of every month";
    } else if(props.data.frequency_settings.day_of_month === "15th") {
      dayOfTheMonth = "On the 15th of every month";
    } else if(props.data.frequency_settings.day_of_month === "last") {
      dayOfTheMonth = "On the last day of every month";
    }
    
    return (
      <span>{dayOfTheMonth} at {hoursOfTheDay[props.data.frequency_settings.hour_of_day]}</span>
    );
  }

  return null;
}

export default PageFrequency;