import React from "react";
import { withRouter } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";
import checkForm from "../../utility/validator";
import plan, { clearCachedPlanInfo } from "../../factories/plan";

// UI
import SubNav, { SubNavText, SubNavLink, SubNavSep } from "../../ui/SubNav";
import Panel, { PanelTitle } from "../../ui/Panel";
import Loading from "../../ui/Loading";
import CookieItem from "./CookieItem";
import CustomActionItem from "./CustomActionItem";
import CountryOptions from "../../ui/CountryOptions";

// Icons
import calendarClockIcon from "../../ui/img/calendar-clock.svg";
import cursorClickIcon from "../../ui/img/cursor-default-click.svg";
import imageSizeIcon from "../../ui/img/image-size.svg";
import emailCheckIcon from "../../ui/img/email-check.svg";
import targetSquareIcon from "../../ui/img/target-square.svg";
import timerIcon from "../../ui/img/timer.svg";
import cookieIcon from "../../ui/img/cookie.svg";
import globeIcon from "../../ui/img/globe.svg";
import eyeClosedIcon from "../../ui/img/eye-closed.svg";
import addIcon from "../../ui/img/add-square.svg";
import minusIcon from "../../ui/img/minus-square.svg";
import formTextboxIcon from "../../ui/img/form-textbox.svg";

class AddPage extends React.Component {
  
  constructor() {
    super();

    this.form = React.createRef();
    this.submit = this.submit.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    
    this.addCookie = this.addCookie.bind(this);
    this.handleCookieInputChange = this.handleCookieInputChange.bind(this);
    this.removeCookie = this.removeCookie.bind(this);

    this.toggleNewActionMenu = this.toggleNewActionMenu.bind(this);
    this.newAction = this.newAction.bind(this);
    this.handleCustomActionInputChange = this.handleCustomActionInputChange.bind(this);
    this.removeCustomAction = this.removeCustomAction.bind(this);

    this.handleKeyboardShortcuts = this.handleKeyboardShortcuts.bind(this);
    this.handleOutsideClicks = this.handleOutsideClicks.bind(this);
    
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleDayOfMonthChange = this.handleDayOfMonthChange.bind(this);
    this.handleSelectNumberChange = this.handleSelectNumberChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    // Dragging and dropping custom actions
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    
    this.state = {
      plan: {},
      user: {},
      data: {
        name: "",
        url: "",
        timestamp_screenshots: false,
        urlstamp_screenshots: false,
        selector: "",
        email_screenshots_to: "",
        screenshot_width: 1440,
        screenshot_height: 0,
        proxy_location: "",
        cookies: [],
        custom_actions: [],
        frequency: "daily",
        frequency_settings: {
          hour_of_day: 9,
          day_of_week: 0,
          day_of_month: "first"
        },
        wait_time: 0
      },
      loading: true,
      formLoading: false,
      error: "",

      show_new_action_menu: false,
      uncollapsed: []
    };
  }
  
  componentDidMount() {
    this.props.setSection("screenshots");

    window.addEventListener("keydown", this.handleKeyboardShortcuts);
    window.addEventListener("click", this.handleOutsideClicks);

    // Get the CSS selector passed in via the URL bar if applicable
    this.handlePassedInData();

    // Get the user's plan information
    plan(resp => {
      this.setState({ plan: resp, loading: false });

      // If they've reached their page limit, redirect to the Upgrade page
      if(resp.usage.pages >= resp.plan.limits.pages) {
        if(resp.plan.name === "trial") {
          this.props.history.push("/page/limit-reached");
        } else {
          this.props.history.push("/settings/manage-subscription");
        }
      }
    });

    // Get the user's info
    http.get("/user/").then(resp => {
      this.setState({
        user: {
          first_name: resp.data.first_name,
          last_name: resp.data.last_name,
          email: resp.data.email,
          timezone: resp.data.timezone
        }
      });
    }).catch((error) => {
      console.error(error);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyboardShortcuts, false);
  }

  handlePassedInData() {
    let pageUrl = this.props.match.params.pageUrl;
    let cssSelector = this.props.match.params.cssSelector;

    if(pageUrl && cssSelector) {
      pageUrl = decodeURIComponent(atob(pageUrl));
      cssSelector = decodeURIComponent(atob(cssSelector));

      const copyOfState = {...this.state};
      copyOfState.data['url'] = pageUrl;
      copyOfState.data['selector'] = cssSelector;
      copyOfState['uncollapsed'] = ['capture-specific-element'];
      this.setState(copyOfState);
    }
  }

  handleKeyboardShortcuts(e) {

    // Save page
    if((e.metaKey || e.ctrlKey) && e.code === 'KeyS') {
      this.submit(e);
      e.preventDefault();
    }

    // Escape Key: Hide new action menu
    if(e.keyCode === 27 && this.state.show_new_action_menu) {
      this.toggleNewActionMenu();
      e.preventDefault();
    }
  }

  handleOutsideClicks(e) {

    // Hide new action menu
    if(e.target.id !== 'new-action-btn' && this.state.show_new_action_menu) {
      this.toggleNewActionMenu();
    }
  }

  handleInputChange({target}) {
    const copyOfDataObject = { ...this.state.data, [target.name]: target.value };
    this.setState({ data: copyOfDataObject });
  }
  
  handleCookieInputChange({target}) {
    const index = parseInt(target.getAttribute("data-index"));
    const copyOfDataObject = {...this.state.data};
    let targetValue = target.value;

    copyOfDataObject.cookies[index][target.name] = targetValue;
    this.setState({ data: copyOfDataObject });
  }

  addCookie(type) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.cookies.push({
      type: type,
      name: '',
      value: ''
    });
    this.setState({ data: copyOfDataObject });
  }

  removeCookie(index) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.cookies.splice(index, 1);
    this.setState({ data: copyOfDataObject });
  }
  
  handleCustomActionInputChange({target}) {
    const index = parseInt(target.getAttribute("data-index"));
    const copyOfDataObject = {...this.state.data};
    let targetValue = target.value;
    let targetName = target.name;

    if(targetName === "wait_time") {
      targetValue = parseFloat(targetValue);
    }

    // Allow for clicking of multiple elements
    if(targetName.includes("multi_")) {
      targetName = "multi";
      targetValue = targetValue === "single" ? false : true;
    }

    copyOfDataObject.custom_actions[index][targetName] = targetValue;
    this.setState({ data: copyOfDataObject });
  }
  
  toggleNewActionMenu() {
    this.setState({ show_new_action_menu: !this.state.show_new_action_menu });
  }

  newAction(type) {
    const new_action = {
      type: type
    };

    switch(type) {
      case "click":
        new_action["selector"] = "";
        new_action["aggressive_click"] = false;
        new_action["multi"] = false;
        break;
      case "hide":
        new_action["selector"] = "";
        break;
      case "type-into-field":
        new_action["selector"] = "";
        new_action["value"] = "";
        break;
      case "add-class":
        new_action["selector"] = "";
        new_action["css_classes"] = "";
        break;
      case "remove-class":
        new_action["selector"] = "";
        new_action["css_classes"] = "";
        break;
      case "go-to-url":
        new_action["url"] = "";
        break;
      case "timed-wait":
        new_action["wait_time"] = 0.5;
        break;
      default:
        break
    }
    
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.custom_actions.push(new_action);
    this.setState({ data: copyOfDataObject });
  }
  
  removeCustomAction(index) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.custom_actions.splice(index, 1);
    this.setState({ data: copyOfDataObject });
  }
  
  handleCheckboxChange({target}) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject[target.name] = !copyOfDataObject[target.name];
    this.setState({ data: copyOfDataObject });
  }

  handleTimeChange({target}) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.frequency_settings.hour_of_day = parseInt(target.value);
    this.setState({ data: copyOfDataObject });
  }

  handleDayChange({target}) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.frequency_settings.day_of_week = parseInt(target.value);
    this.setState({ data: copyOfDataObject });
  }

  handleDayOfMonthChange({target}) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.frequency_settings.day_of_month = target.value;
    this.setState({ data: copyOfDataObject });
  }

  handleSelectNumberChange({target}) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject[target.name] = parseInt(target.value);
    this.setState({ data: copyOfDataObject });
  }

  handleSelectChange({target}) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject[target.name] = target.value;
    this.setState({ data: copyOfDataObject });
  }

  handleDragStart(e, index) {
    this.setState({ dragged_item_index: index });
  }

  handleDragEnter(e, index) {
    this.setState({ dragged_over_index: index });
  }

  handleDragEnd(index) {
    const copyOfData = {...this.state.data};
    const draggedItem = copyOfData.custom_actions[this.state.dragged_item_index];

    copyOfData.custom_actions.splice(this.state.dragged_item_index, 1);
    copyOfData.custom_actions.splice(this.state.dragged_over_index, 0, draggedItem);

    this.setState({
      data: copyOfData,
      dragged_item_index: null,
      dragged_over_index: null
    });
  }

  formError() {
    return this.state.error ? <div className="form-error">{this.state.error}</div> : null;
  }

  submit(event) {
    document.activeElement.blur();
    this.setState({ error: "" });
    
    if(checkForm(this.form.current)) {
      this.setState({ formLoading: true });
      
      http.post("/page/", this.state.data).then(resp => {
        const nowEpoch = new Date().valueOf();
        clearCachedPlanInfo();
        this.props.history.push(`/page/${resp.data.id}/take-screenshot/${nowEpoch}`);
      }).catch((error) => {
        this.setState({ formLoading: false, error: getErrorMessage(error) });
      });
    }

    event.preventDefault();
  }

  toggleCollapse(blockName) {
    const copyOfUncollapsed = [...this.state.uncollapsed];
    const indexOfBlockName = copyOfUncollapsed.indexOf(blockName);
    
    if(indexOfBlockName > -1) {
      copyOfUncollapsed.splice(indexOfBlockName, 1);
    } else {
      copyOfUncollapsed.push(blockName);
    }

    this.setState({ uncollapsed: copyOfUncollapsed });
  }
  
  render() {

    let TimeOfDayOptions = () => null;
    let DayOfWeekOptions = () => null;
    let DayOfMonthOptions = () => null;

    // Time-of-day frequency settings
    if(["daily", "weekly", "monthly"].indexOf(this.state.data.frequency) > -1) {
      TimeOfDayOptions = () => {
        return (
          <div className="flex-item flex-item--50">
            <label htmlFor="hour_of_day" className="input-label">Time of Day</label>
            <div className="select-wrapper">
              <select name="hour_of_day" id="hour_of_day" className="input-field" onChange={this.handleTimeChange} value={this.state.data.frequency_settings.hour_of_day} required data-error-text="Required">
                <option value="0">12:00 AM</option>
                <option value="1">1:00 AM</option>
                <option value="2">2:00 AM</option>
                <option value="3">3:00 AM</option>
                <option value="4">4:00 AM</option>
                <option value="5">5:00 AM</option>
                <option value="6">6:00 AM</option>
                <option value="7">7:00 AM</option>
                <option value="8">8:00 AM</option>
                <option value="9">9:00 AM</option>
                <option value="10">10:00 AM</option>
                <option value="11">11:00 AM</option>
                <option value="12">12:00 PM</option>
                <option value="13">1:00 PM</option>
                <option value="14">2:00 PM</option>
                <option value="15">3:00 PM</option>
                <option value="16">4:00 PM</option>
                <option value="17">5:00 PM</option>
                <option value="18">6:00 PM</option>
                <option value="19">7:00 PM</option>
                <option value="20">8:00 PM</option>
                <option value="21">9:00 PM</option>
                <option value="22">10:00 PM</option>
                <option value="23">11:00 PM</option>
              </select>
            </div>
          </div>
        );
      };
    }

    // Day-of-week frequency settings
    if(["weekly"].indexOf(this.state.data.frequency) > -1) {
      DayOfWeekOptions = () => {
        return (
          <div className="flex-item flex-item--50">
            <label htmlFor="day_of_week" className="input-label">Day of the Week</label>
            <div className="select-wrapper">
              <select name="day_of_week" id="day_of_week" className="input-field" onChange={this.handleDayChange} value={this.state.data.frequency_settings.day_of_week} required data-error-text="Required">
                <option value="6">Sunday</option>
                <option value="0">Monday</option>
                <option value="1">Tuesday</option>
                <option value="2">Wednesday</option>
                <option value="3">Thursday</option>
                <option value="4">Friday</option>
                <option value="5">Saturday</option>
              </select>
            </div>
          </div>
        );
      };
    }

    // Day-of-month frequency settings
    if(["monthly"].indexOf(this.state.data.frequency) > -1) {
      DayOfMonthOptions = () => {
        return (
          <div className="flex-item flex-item--50">
            <label htmlFor="day_of_month" className="input-label">Time of the Month</label>
            <div className="select-wrapper">
              <select name="day_of_month" id="day_of_month" className="input-field" onChange={this.handleDayOfMonthChange} value={this.state.data.frequency_settings.day_of_month} required data-error-text="Required">
                <option value="first">First day of the month</option>
                <option value="15th">15th of the month</option>
                <option value="last">Last day of the month</option>
              </select>
            </div>
          </div>
        );
      };
    }
    
    if(this.state.loading) {
      return null;
    } else {

      let hourlyOptionText = "Hourly";
      let hourlyDisabled = false;
  
      if(this.state.plan.plan.limits.hourly_pages === 0) {
        hourlyOptionText = "Hourly - Requires Pro/Business Plan";
        hourlyDisabled = true;
      } else if(this.state.plan.usage.hourly_pages >= this.state.plan.plan.limits.hourly_pages && this.state.data.original_frequency !== "hourly") {
        hourlyOptionText = "Hourly - Limit Reached";
        hourlyDisabled = true;
      }
      
      return (
        <div>

          <SubNav>
            <SubNavLink to="/">Pages</SubNavLink>
            <SubNavSep />
            <SubNavText>Add new page</SubNavText>
          </SubNav>

          <Panel wide hasTitle>

            <PanelTitle>New Page</PanelTitle>
          
            <form ref={this.form} name="add_page_form" onSubmit={this.submit} noValidate className={`${this.state.formLoading ? 'loading' : ''}`}>
              <div className="flex-container">

                <div className="flex-item">
                  <label htmlFor="page_name" className="input-label">Page Name</label>
                  <input type="text" name="name" autoFocus onChange={this.handleInputChange} value={this.state.data.name} id="page_name" className="input-field" required data-error-text="Please enter a nickname for this page" />
                </div>

                <div className="flex-item">
                  <label htmlFor="page_url" className="input-label">Page URL</label>
                  <input type="url" name="url" onChange={this.handleInputChange} value={this.state.data.url} id="page_url" className="input-field" required data-error-text="Please enter a valid website URL" />
                </div>

                <div className={`flex-item ${this.state.data.frequency === 'daily' ? 'flex-item--50' : ''}`}>
                  <label htmlFor="frequency" className="input-label">Screenshot Frequency</label>
                  <div className="select-wrapper">
                    <select name="frequency" id="frequency" className="input-field" onChange={this.handleInputChange} value={this.state.data.frequency} required data-error-text="Required">
                      <option value="hourly" disabled={hourlyDisabled}>{hourlyOptionText}</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </div>
                </div>

                <DayOfWeekOptions />
                <DayOfMonthOptions />
                <TimeOfDayOptions />

                <hr />

                <div className="flex-item">
                
                  <h2>Advanced Settings</h2>

                  {/* Screenshot Size */}
                  <div className={`collapsible ${this.state.uncollapsed.indexOf('screenshot-size') > -1 ? 'collapsible--open' : ''}`}>
                    <div className="collapsible__title" onClick={() => this.toggleCollapse('screenshot-size')}>
                      <img src={ imageSizeIcon } alt="Screenshot Size" />
                      Screenshot Size
                    </div>
                    <div className="collapsible__content">
                      <div className="flex-container">
                    
                        <div className="flex-item flex-item--50">
                          <label htmlFor="screenshot_width" className="input-label input-label--dark">Width</label>
                          <div className="select-wrapper">
                            <select name="screenshot_width" id="screenshot_width" className="input-field" onChange={this.handleSelectNumberChange} value={this.state.data.screenshot_width} required data-error-text="Required">
                              <option value="320">320px (iPhone 5/SE)</option>
                              <option value="375">375px (iPhone 6/7/8/X/XS)</option>
                              <option value="414">414px (iPhone 6/7/8 Plus)</option>
                              <option value="480">480px</option>
                              <option value="768">768px (iPad)</option>
                              <option value="834">834px (iPad Pro 10.5")</option>
                              <option value="1024">1024px (iPad Pro 12.9")</option>
                              <option value="1280">1280px</option>
                              <option value="1440">1440px (Desktop)</option>
                              <option value="1600">1600px</option>
                              <option value="1920">1920px</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex-item flex-item--50">
                          <label htmlFor="screenshot_height" className="input-label input-label--dark">Height</label>
                          <div className="select-wrapper">
                            <select name="screenshot_height" id="screenshot_height" className="input-field" onChange={this.handleSelectNumberChange} value={this.state.data.screenshot_height} required data-error-text="Required">
                              <option value="0">Auto (Full Page)</option>
                              <option value="768">768px</option>
                              <option value="900">900px</option>
                              <option value="1080">1080px</option>
                              <option value="1200">1200px</option>
                              <option value="2000">2000px</option>
                              <option value="3000">3000px</option>
                              <option value="4000">4000px</option>
                              <option value="5000">5000px</option>
                              <option value="6000">6000px</option>
                              <option value="7000">7000px</option>
                              <option value="8000">8000px</option>
                              <option value="9000">9000px</option>
                              <option value="10000">10000px</option>
                              <option value="11000">11000px</option>
                              <option value="12000">12000px</option>
                              <option value="13000">13000px</option>
                              <option value="14000">14000px</option>
                              <option value="15000">15000px</option>
                              <option value="16000">16000px</option>
                              <option value="17000">17000px</option>
                              <option value="18000">18000px</option>
                              <option value="19000">19000px</option>
                              <option value="20000">20000px</option>
                            </select>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* Capture Specific Element */}
                  <div className={`collapsible ${this.state.uncollapsed.indexOf('capture-specific-element') > -1 ? 'collapsible--open' : ''}`}>
                    <div className="collapsible__title" onClick={() => this.toggleCollapse('capture-specific-element')}>
                      <img src={ targetSquareIcon } alt="Target" />
                      Capture Specific Element
                    </div>
                    <div className="collapsible__content">
                      <div className="flex-container">
                      
                        <div className="flex-item">
                        <p>Use our <a href="https://screenlapse.com/browser-extensions" target="_blank" rel="noopener noreferrer">browser extension</a> to find any element's CSS selector.</p>
                          <label htmlFor="page_selector" className="input-label input-label--dark">
                            Enter the element's CSS selector or XPath:
                          </label>
                          <input type="text" name="selector" onChange={this.handleInputChange} placeholder="Example: div#pricing-table or /html/body/div[1]" value={this.state.data.selector} id="page_selector" className="input-field" />
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* Wait Before Screenshot */}
                  <div className={`collapsible ${this.state.uncollapsed.indexOf('wait-before-screenshot') > -1 ? 'collapsible--open' : ''}`}>
                    <div className="collapsible__title" onClick={() => this.toggleCollapse('wait-before-screenshot')}>
                      <img src={ timerIcon } alt="Timer" />
                      Wait for Content to Load
                    </div>
                    <div className="collapsible__content">
                      <div className="flex-container">
                      
                        <div className="flex-item">
                          <label htmlFor="wait_time" className="input-label input-label--dark">Sometimes images and other types of content need a little extra time to load.</label>
                          <div className="select-wrapper">
                            <select name="wait_time" id="wait_time" className="input-field" onChange={this.handleSelectNumberChange} value={this.state.data.wait_time} required data-error-text="Required">
                              <option value="0">Standard wait time</option>
                              <option value="1">1 seconds</option>
                              <option value="2">2 seconds</option>
                              <option value="3">3 seconds</option>
                              <option value="4">4 seconds</option>
                              <option value="5">5 seconds</option>
                              <option value="6">6 seconds</option>
                              <option value="7">7 seconds</option>
                              <option value="8">8 seconds</option>
                              <option value="9">9 seconds</option>
                              <option value="10">10 seconds</option>
                              <option value="11">11 seconds</option>
                              <option value="12">12 seconds</option>
                              <option value="13">13 seconds</option>
                              <option value="14">14 seconds</option>
                              <option value="15">15 seconds</option>
                            </select>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* Cookie Management */}
                  <div className={`collapsible ${this.state.uncollapsed.indexOf('cookie-management') > -1 ? 'collapsible--open' : ''}`}>
                    <div className="collapsible__title" onClick={() => this.toggleCollapse('cookie-management')}>
                      <img src={ cookieIcon } alt="Cookie" />
                      Cookie Management
                    </div>
                    <div className="collapsible__content">
                      <div className="flex-container">
                      
                        <div className="flex-item">
                          <p>Add cookies and other session data to capture logged-in versions of pages.</p>
                        </div>

                        <div className="flex-item">
                          {this.state.data.cookies.map((cookie, index) => <CookieItem key={index} index={index} handleCookieInputChange={this.handleCookieInputChange} removeCookie={this.removeCookie} cookie={cookie} />)}
                        </div>

                        <div className="flex-item">
                          <button type="button" id="add-cookie-btn" className="btn btn--secondary btn--sm btn--full-width btn--mb btn--push-top" onClick={() => this.addCookie('cookie')}>New Cookie</button>
                          <button type="button" id="add-localstorage-btn" className="btn btn--secondary btn--sm btn--full-width btn--mb" onClick={() => this.addCookie('local_storage')}>New Local Storage Item</button>
                          <button type="button" id="add-localstorage-btn" className="btn btn--secondary btn--sm btn--full-width btn--mb" onClick={() => this.addCookie('session_storage')}>New Session Storage Item</button>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* Custom Action Sequence */}
                  <div className={`collapsible ${this.state.uncollapsed.indexOf('custom-actions-before-screenshot') > -1 ? 'collapsible--open' : ''}`}>
                    <div className="collapsible__title" onClick={() => this.toggleCollapse('custom-actions-before-screenshot')}>
                      <img src={ cursorClickIcon } alt="Cursor Click" />
                      Custom Action Sequence
                    </div>
                    <div className="collapsible__content">
                      <div className="flex-container">

                        <div className="flex-item">
                          <p>Interact with the page and manipulate elements before the screenshot is taken. Use our <a href="https://screenlapse.com/browser-extensions" target="_blank" rel="noopener noreferrer">browser extension</a> to find any element's CSS selector.</p>
                        </div>

                        <div className="flex-item">
                          {this.state.data.custom_actions.map((action, index) => <CustomActionItem key={index} index={index} action={action} handleCustomActionInputChange={this.handleCustomActionInputChange} removeCustomAction={this.removeCustomAction} onDragStart={(e) => this.handleDragStart(e, index)} onDragEnter={(e) => this.handleDragEnter(e, index)} onDragEnd={() => this.handleDragEnd()} draggedItemIndex={this.state.dragged_item_index} draggedOverIndex={this.state.dragged_over_index} />)}
                        </div>

                        <div className="flex-item">

                          <button type="button" id="new-action-btn" className={`btn btn--secondary btn--sm btn--full-width btn--mb btn--push-top ${this.state.show_new_action_menu ? 'btn--active' : ''}`} onClick={this.toggleNewActionMenu}>New Action</button>

                          <div className={`context-menu ${this.state.show_new_action_menu ? 'context-menu--show' : ''}`}>
                            <div className="context-menu__options">
                        
                              <button type="button" className="context-menu__option" onClick={() => this.newAction('click')}>
                                <img src={ cursorClickIcon } alt="Cursor Click" />
                                Click Element
                              </button>

                              <button type="button" className="context-menu__option" onClick={() => this.newAction('hide')}>
                                <img src={ eyeClosedIcon } alt="Eye Closed" />
                                Hide Element
                              </button>

                              <button type="button" className="context-menu__option" onClick={() => this.newAction('type-into-field')}>
                                <img src={ formTextboxIcon } alt="Textbox" />
                                Type Into Field
                              </button>

                              <button type="button" className="context-menu__option" onClick={() => this.newAction('add-class')}>
                                <img src={ addIcon } alt="Add" />
                                Add CSS Class
                              </button>

                              <button type="button" className="context-menu__option" onClick={() => this.newAction('remove-class')}>
                                <img src={ minusIcon } alt="Minus" />
                                Remove CSS Class
                              </button>

                              <button type="button" className="context-menu__option" onClick={() => this.newAction('go-to-url')}>
                                <img src={ globeIcon } alt="Globe" />
                                Go to URL
                              </button>

                              <button type="button" className="context-menu__option" onClick={() => this.newAction('timed-wait')}>
                                <img src={ timerIcon } alt="Timer" />
                                Timed Wait
                              </button>

                            </div>
                          </div>
                          
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* Watermarks on Screenshots */}
                  <div className={`collapsible ${this.state.uncollapsed.indexOf('print-metadata') > -1 ? 'collapsible--open' : ''}`}>
                    <div className="collapsible__title" onClick={() => this.toggleCollapse('print-metadata')}>
                      <img src={ calendarClockIcon } alt="Timestamp" />
                      Print Metadata on Screenshots
                    </div>
                    <div className="collapsible__content collapsible__content--padded">
                      <div className="flex-container">
                      
                        <div className="flex-item">
                          <input type="checkbox" name="timestamp_screenshots" onChange={this.handleCheckboxChange} checked={this.state.data.timestamp_screenshots} id="page_timestamp_screenshots" className="input-checkbox" />
                          <label htmlFor="page_timestamp_screenshots" className="input-label input-label--dark input-label--inline">Add the date and time to each screenshot image</label>
                        </div>

                        <div className="flex-item">
                          <input type="checkbox" name="urlstamp_screenshots" onChange={this.handleCheckboxChange} checked={this.state.data.urlstamp_screenshots} id="page_urlstamp_screenshots" className="input-checkbox" />
                          <label htmlFor="page_urlstamp_screenshots" className="input-label input-label--dark input-label--inline">Add the page URL to each screenshot image</label>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* Residential Proxies */}
                  <div className={`collapsible ${this.state.uncollapsed.indexOf('geographic-location') > -1 ? 'collapsible--open' : ''}`}>
                    <div className="collapsible__title" onClick={() => this.toggleCollapse('geographic-location')}>
                      <img src={ globeIcon } alt="Globe" />
                      Residential Proxies
                    </div>
                    <div className="collapsible__content">
                      <div className="flex-container">
                    
                        <div className="flex-item">

                          <p>Use proxies for difficult websites (i.e. google.com) or when you need to capture screenshots from a particular country.</p>
                          
                          <label htmlFor="proxy_location" className="input-label input-label--dark">Select a proxy location:</label>
                          
                          <div className="select-wrapper">
                            <select name="proxy_location" id="proxy_location" className="input-field" onChange={this.handleSelectChange} value={this.state.data.proxy_location}>
                              <option value="">-- No Proxy --</option>
                              <CountryOptions/>
                            </select>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                  {/* Email Screenshots */}
                  <div className={`collapsible ${this.state.uncollapsed.indexOf('email-screenshots') > -1 ? 'collapsible--open' : ''}`}>
                    <div className="collapsible__title" onClick={() => this.toggleCollapse('email-screenshots')}>
                      <img src={ emailCheckIcon } alt="Send Email" />
                      Email Screenshots
                    </div>
                    <div className="collapsible__content collapsible__content--padded">
                      <div className="flex-container">
                      
                        <div className="flex-item">
                          <label htmlFor="page_email_screenshots_to" className="input-label input-label--dark">Send scheduled screenshots to these email addresses. Separate addresses with a comma (or comma + space):</label>
                          <input type="text" name="email_screenshots_to" onChange={this.handleInputChange} placeholder="email1@domain.com, email2@domain.com" value={this.state.data.email_screenshots_to} id="page_email_screenshots_to" className="input-field" />
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

                {this.formError()}

                <div className="flex-item centered">
                  <input type="submit" value="Add Page" className="btn btn--wide btn--push-top" />
                </div>

              </div>
            </form>

            <Loading loading={this.state.formLoading} />
            
          </Panel>

          <div className="chat-widget-breathing-room"></div>
          
        </div>
      );
    }
  }
}

export default withRouter(AddPage);