import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.compiled.css";

// Pages
import Header from "./components/ui/Header";
import PageList from "./components/pages/dashboard/PageList";
import PageDetail from "./components/pages/dashboard/PageDetail";
import AddPage from "./components/pages/dashboard/AddPage";
import PageLimitReached from "./components/pages/dashboard/PageLimitReached";
import EditPage from "./components/pages/dashboard/EditPage";
import TakeScreenshot from "./components/pages/dashboard/TakeScreenshot";
import Settings from "./components/pages/settings/Settings";
import ManageSubsciption from "./components/pages/settings/ManageSubsciption";
import DropboxConnected from "./components/pages/settings/DropboxConnected";
import UpdateCard from "./components/pages/settings/UpdateCard";
import Signup from "./components/pages/Signup";
import Login from "./components/pages/Login";
import OauthHandler from "./components/pages/OauthHandler";
import Logout from "./components/pages/Logout";
import ForgotPassword from "./components/pages/ForgotPassword";
import CreateNewPassword from "./components/pages/CreateNewPassword";
import Subscribe from "./components/pages/Subscribe";
import SubscriptionCancelled from "./components/pages/SubscriptionCancelled";
import PageNotFound from "./components/pages/PageNotFound";

// UI
import ScrollTop from "./components/ui/ScrollTop";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");
  return accessToken && refreshToken ? <Route {...rest} /> : <Redirect to={`/login?redirect=${rest.location.pathname}`} />;
};

class App extends Component {

  constructor() {
    super();

    this.setSection = this.setSection.bind(this);

    this.state = {
      section: ""
    };
  }
  
  setSection(section) {
    this.setState({
      section: section
    });
  }
  
  render() {
    return (
      <BrowserRouter>
        <ScrollTop>
          <div className="app">
            <Header section={this.state.section} />
            <Switch>
              <ProtectedRoute exact path="/" render={() => <PageList setSection={this.setSection} />} />
              <ProtectedRoute path="/settings/dropbox-connected" render={() => <DropboxConnected setSection={this.setSection} />} />
              <ProtectedRoute path="/settings/manage-subscription" render={() => <ManageSubsciption setSection={this.setSection} />} />
              <ProtectedRoute path="/settings/update-card" render={() => <UpdateCard setSection={this.setSection} />} />
              <ProtectedRoute path="/settings" render={() => <Settings setSection={this.setSection} />} />
              <ProtectedRoute path="/page/limit-reached" render={() => <PageLimitReached setSection={this.setSection} />} />
              <ProtectedRoute path="/page/add/:pageUrl/:cssSelector" render={() => <AddPage setSection={this.setSection} />} />
              <ProtectedRoute path="/page/add" render={() => <AddPage setSection={this.setSection} />} />
              <ProtectedRoute path="/page/:id/edit" render={() => <EditPage setSection={this.setSection} />} />
              <ProtectedRoute path="/page/:id/take-screenshot/:timestamp" render={() => <TakeScreenshot setSection={this.setSection} />} />
              <ProtectedRoute path="/page/:id" render={() => <PageDetail setSection={this.setSection} />} />
              <Route exact path="/signup" render={() => <Signup setSection={this.setSection} />} />
              <Route exact path="/login" render={() => <Login setSection={this.setSection} />} />
              <Route exact path="/oauth" render={() => <OauthHandler />} />
              <Route exact path="/logout" render={() => <Logout setSection={this.setSection} />} />
              <Route exact path="/forgot-password" render={() => <ForgotPassword setSection={this.setSection} />} />
              <Route exact path="/create-new-password/:fpk" render={() => <CreateNewPassword setSection={this.setSection} />} />
              <Route exact path="/subscribe/:userID/:trialKey" render={() => <Subscribe setSection={this.setSection} />} />
              <Route exact path="/goodbye" render={() => <SubscriptionCancelled setSection={this.setSection} />} />
              <Route render={() => <PageNotFound setSection={this.setSection} />} />
            </Switch>
          </div>
        </ScrollTop>
      </BrowserRouter>
    );
  }
}

export default App;