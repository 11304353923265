import React from "react";
import { withRouter } from "react-router-dom";
import plan, { clearCachedPlanInfo } from "../../factories/plan";
import http from "../../utility/http";

// UI
import SubNav, { SubNavText, SubNavLink, SubNavSep } from "../../ui/SubNav";
import Loading from "../../ui/Loading";
import PricingPlans from "../../ui/PricingPlans";

class ManageSubsciption extends React.Component {

  constructor() {
    super();

    this.timers = [];
    this.selectPlan = this.selectPlan.bind(this);
    this.saveNewSubscription = this.saveNewSubscription.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);

    this.state = {
      data: {},
      loading: true,
      planChangeLoading: false,
      successVisible: false,
      success: false
    };
  }
  
  componentDidMount() {
    this.props.setSection("settings");
    this.getPlanInfo();
  }

  componentWillUnmount() {
    this.timers.forEach(tm => clearTimeout(tm));
  }

  getPlanInfo() {

    // Get the user's plan information
    plan(resp => {

      if(resp.plan.name === "trial") {
        this.props.history.push("/settings");
      } else {
        this.setState({
          plan: resp,
          data: {
            plan: resp.plan.name
          },
          loading: false
        });
      }
      
    });
  }

  selectPlan(e, plan) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.plan = plan;
    this.setState({ data: copyOfDataObject });
    e.preventDefault();
  }

  saveNewSubscription(e) {
    this.setState({ planChangeLoading: true });

    http.put("/user/change-subscription/", this.state.data).then(resp => {
      clearCachedPlanInfo();
      this.getPlanInfo();
      this.setState({ planChangeLoading: false });
      this.formShowSuccess("Your subscription has been updated!");
    }).catch(error => {
      this.setState({ planChangeLoading: false, failed_message: error.response.data.message });
    });
    
    e.preventDefault();
  }

  cancelSubscription(e) {
    const confirmation = window.confirm("Are you sure you want to cancel your subscription?");
    
    if(confirmation) {
      this.setState({ cancellationLoading: true });

      http.get("/user/cancel-subscription/").then(resp => {
        clearCachedPlanInfo();
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("plan");
        this.props.history.push("/goodbye");
      }).catch(error => {
        this.setState({ cancellationLoading: false });
      });
    }

    e.preventDefault();
  }

  formShowSuccess(text) {
    this.setState({ success: text });
    
    this.timers[this.timers.length] = setTimeout(() => {
      this.setState({ successVisible: true });
      
      this.timers[this.timers.length] = setTimeout(() => {
        this.setState({ successVisible: false });

        this.timers[this.timers.length] = setTimeout(() => {
          this.setState({ success: false });
        }, 300);
      }, 3000);

    }, 10);
  }
  
  formSuccess() {
    return this.state.success ? <div className={`form-success form-success--short ${this.state.successVisible ? 'form-success--visible' : ''}`}>{this.state.success}</div> : null;
  }
  
  render() {

    const Content = () => {
      if(!this.state.loading) {

        let pageLimitReached = (this.state.plan.usage.pages >= this.state.plan.plan.limits.pages);
        let hourlyPagesLimitReached = (this.state.plan.usage.hourly_pages >= this.state.plan.plan.limits.hourly_pages && this.state.plan.plan.limits.hourly_pages > 0);

        if(pageLimitReached && hourlyPagesLimitReached) {
          return (
            <div>
              <h2>Oh no! You've hit the limit</h2>
              <p>You've reached your hourly screenshots limit and your total web pages limit.</p>
              <p>Please choose a larger plan to increase your limits. Or <a href="mailto:support@screenlapse.com">contact support</a> to discuss a custom plan that fits your needs.</p>
            </div>
          );
        }
        
        if(pageLimitReached) {
          return (
            <div>
              <h2>Oh no! You've reached your page limit</h2>
              <p>Please choose a larger plan to increase your limits. Or <a href="mailto:support@screenlapse.com">contact support</a> to discuss a custom plan that fits your needs.</p>
            </div>
          );
        }

        if(hourlyPagesLimitReached) {
          return (
            <div>
              <h2>You've hit the limit for hourly screenshots</h2>
              <p>Please choose a larger plan to increase your limits. Or <a href="mailto:support@screenlapse.com">contact support</a> to discuss a custom plan that fits your needs.</p>
            </div>
          );
        }

        return (
          <div>
            <h2>Upgrade / Downgrade</h2>
            <p>Straightforward pricing and no hidden fees. You can change plans or cancel at any time.</p>
          </div>
        );
      }
      return null;
    };

    return (
      <div>

        <SubNav>
          <SubNavLink to="/settings">Settings</SubNavLink>
          <SubNavSep />
          <SubNavText>Manage Subscription</SubNavText>
        </SubNav>

        <div className="content-area content-area--npt centered">
          <Content/>
        </div>

        {this.state.loading ? <Loading loading={this.state.loading} /> : <PricingPlans plan={this.state.data.plan} currentPlan={this.state.plan.plan.name} selectPlan={this.selectPlan} />}

        <div className="content-area content-area--padded-more centered">
          {!this.state.loading && this.state.failed_message ? <div className="form-error form-error--floating">{this.state.failed_message}</div> : ''}
          {!this.state.loading && !this.state.planChangeLoading && !this.state.success && this.state.data.plan !== this.state.plan.plan.name ? <button className="btn" onClick={this.saveNewSubscription}>Update Subscription</button> : ''}
          {this.formSuccess()}
          <Loading loading={this.state.planChangeLoading} />
        </div>

        <div className="secondary-action-area centered">
          <p>You can cancel your subscription at any time. Cancellation will cause all of your stored screenshots to be permanently deleted. Please <a href="mailto:support@screenlapse.com">contact support</a> with any questions.</p>
          {!this.state.cancellationLoading ? <button className="btn btn--sm btn--secondary" onClick={this.cancelSubscription}>Cancel Subscription</button> : null}
          <Loading loading={this.state.cancellationLoading} />
        </div>

      </div>
    );
  }
}

export default withRouter(ManageSubsciption);