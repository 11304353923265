import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import http, { getErrorMessage } from "../utility/http";
import checkForm from "../utility/validator";

// UI
import Panel, { PanelTitle } from "../ui/Panel";
import Loading from "../ui/Loading";
import TimezonePicker from "react-timezone";
import GoogleLogoMark from "../ui/img/google-logo-mark.svg";

class Signup extends React.Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);

    this.state = {
      data: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        timezone: "America/New_York",
      },
      loading: false,
      error: ""
    };
  }
  
  componentDidMount() {
    this.props.setSection("signup");
  }

  handleInputChange({target}) {
    const copyOfDataObject = { ...this.state.data, [target.name]: target.value };
    this.setState({ data: copyOfDataObject });
  }

  handleTimezoneChange(timezone) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.timezone = timezone;
    this.setState({ data: copyOfDataObject });
  }

  formError() {
    return this.state.error ? <div className="form-error">{this.state.error}</div> : null;
  }

  submit(event) {
    this.setState({ error: "" });
    document.activeElement.blur();

    if(checkForm(event.target)) {
      this.setState({ loading: true });

      http.post("/user/", this.state.data).then(resp => {
        
        localStorage.setItem("access_token", resp.data.access_token);
        localStorage.setItem("refresh_token", resp.data.refresh_token);
        localStorage.setItem("timezone", resp.data.timezone);
        
        // Provide the chat widget with some user identifiers
        window.tidioChatApi.setVisitorData({
          distinct_id: resp.data.id,
          email: resp.data.email,
          name: resp.data.first_name + " " + resp.data.last_name
        });

        // Fathom Analytics Event - User Signup
        if(process.env.REACT_APP_ENV === "production") {
          if(typeof window.fathom !== "undefined") {
            window.fathom.trackGoal('EXYJ6TCR', 0);
          }
        }
        
        this.props.history.push("/");

      }).catch((error) => {
        this.setState({ loading: false, error: getErrorMessage(error) });
      });
    }
    
    event.preventDefault();
  }
  
  render() {

    // Redirect logged-in users to the main app
    if(localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
      return <Redirect to="/" />;
    }

    return (
      <div>

        <div className="content-area centered">
          
          <h2>Start your FREE 14-day trial now</h2>
          <p>No credit card required. Start capturing website screenshots right away!</p>

        </div>
        
        <Panel spaced semiWide hasTitle>

          <PanelTitle>Get Started</PanelTitle>
          
          <form name="signup_form" onSubmit={this.submit} noValidate className={`${this.state.loading ? 'loading' : ''}`}>
            <div className="flex-container">

              <div className="flex-item flex-item--50">
                <label htmlFor="first_name" className="input-label">First Name</label>
                <input type="text" name="first_name" autoFocus onChange={this.handleInputChange} defaultValue={this.state.data.first_name} id="first_name" className="input-field" required data-error-text="Required" />
              </div>

              <div className="flex-item flex-item--50">
                <label htmlFor="last_name" className="input-label">Last Name</label>
                <input type="text" name="last_name" onChange={this.handleInputChange} defaultValue={this.state.data.last_name} id="last_name" className="input-field" required data-error-text="Required" />
              </div>

              <div className="flex-item">
                <label htmlFor="email" className="input-label">Email Address</label>
                <input type="email" name="email" onChange={this.handleInputChange} value={this.state.data.email} id="email" className="input-field" required data-error-text="Please enter a valid email address" />
              </div>
              
              <div className="flex-item">
                <label htmlFor="password" className="input-label">Password</label>
                <input type="password" name="password" onChange={this.handleInputChange} value={this.state.data.password} id="password" className="input-field" required data-error-text="Please create a password" />
              </div>

              <div className="flex-item">
                <label htmlFor="timezone" className="input-label">Timezone</label>
                <div className="select-wrapper select-wrapper--timezone">
                  <TimezonePicker
                    id="timezone"
                    name="timezone"
                    className="input-field--timezone"
                    onChange={this.handleTimezoneChange}
                    value={this.state.data.timezone}
                    inputProps={{
                      placeholder: 'Select Timezone...',
                      name: 'timezone',
                    }}
                  />
                </div>
              </div>

              {this.formError()}

              <div className="flex-item centered">
                <input type="submit" value="Create Account" className="btn btn--wide btn--push-top" />
              </div>
  
            </div>
          </form>

          <Loading loading={this.state.loading} />

          <div className="oauth-options">

            <span className="or">
              <span className="or__text">or</span>
            </span>

            <a href={`${process.env.REACT_APP_API_HOST}/user/login/oauth`} className="google-oath-button">
              <img src={GoogleLogoMark} alt="Google" />
              <span>Sign up with Google</span>
            </a>

          </div>

        </Panel>

      </div>
    );
  }
}

export default withRouter(Signup);