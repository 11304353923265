import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import { withRouter } from "react-router-dom";
import http, { getErrorMessage } from "../../utility/http";
import checkForm from "../../utility/validator";

// UI
import Panel, { PanelTitle } from "../../ui/Panel";
import Loading from "../../ui/Loading";
import TimezonePicker from "react-timezone";
import SubscriptionPanel from "./SubscriptionPanel";
import SyncAppsPanel from "./SyncAppsPanel";

class Settings extends React.Component {

  constructor() {
    super();

    this.timers = [];
    this.updateUserData = this.updateUserData.bind(this);
    this.submitBasicInfoForm = this.submitBasicInfoForm.bind(this);
    this.submitPasswordForm = this.submitPasswordForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);

    this.state = {
      data: {
        first_name: "",
        last_name: "",
        email: "",
        timezone: "America/New_York",
        date_format_pref: "MM-DD-YYYY"
      },
      user: {},
      loadingUserData: true,
      loading_basic_info_form: true,
      loading_password_form: false
    };
  }
  
  componentDidMount() {
    this.props.setSection("settings");

    // Set the user's timezone so Moment.js dates are correctly formatted
    const timezone = localStorage.getItem("timezone");

    if(timezone) {
      Moment.globalTimezone = timezone;
    }

    // Get the user's info
    http.get("/user/").then(resp => {
      
      this.setState({
        data: {
          first_name: resp.data.first_name,
          last_name: resp.data.last_name,
          email: resp.data.email,
          timezone: resp.data.timezone,
          date_format_pref: resp.data.date_format_pref
        },
        user: resp.data,
        loadingUserData: false,
        loading_basic_info_form: false,
        loading_password_form: false
      });

    }).catch((error) => {
      console.error(error);
    });
  }

  componentWillUnmount() {
    this.timers.forEach(tm => clearTimeout(tm));
  }

  updateUserData(new_user_data) {
    this.setState({ user: new_user_data });
  }

  handleInputChange({target}) {
    const copyOfDataObject = { ...this.state.data, [target.name]: target.value };
    this.setState({ data: copyOfDataObject });
  }

  handleTimezoneChange(timezone) {
    const copyOfDataObject = { ...this.state.data };
    copyOfDataObject.timezone = timezone;
    this.setState({ data: copyOfDataObject });
    localStorage.removeItem("timezone_preference_missing");
  }

  formShowSuccess(formName, text) {
    this.setState({ [`success_${formName}`]: text });
    
    this.timers[this.timers.length] = setTimeout(() => {
      this.setState({ successVisible: true });
      
      this.timers[this.timers.length] = setTimeout(() => {
        this.setState({ successVisible: false });

        this.timers[this.timers.length] = setTimeout(() => {
          this.setState({ [`success_${formName}`]: false });
        }, 300);
      }, 2000);

    }, 10);
  }
  
  formSuccess(formName) {
    return this.state[`success_${formName}`] ? <div className={`form-success ${this.state.successVisible ? 'form-success--visible' : ''}`}>{this.state[`success_${formName}`]}</div> : null;
  }
  
  formError(formName) {
    return this.state[`error_${formName}`] ? <div className="form-error">{this.state[`error_${formName}`]}</div> : null;
  }

  submitBasicInfoForm(event) {
    document.activeElement.blur();
    this.setState({ error_basic_info_form: "" });
    
    if(checkForm(event.target)) {
      this.setState({ loading_basic_info_form: true });

      const dataToSave = {
        first_name: this.state.data.first_name,
        last_name: this.state.data.last_name,
        email: this.state.data.email,
        timezone: this.state.data.timezone,
        date_format_pref: this.state.data.date_format_pref
      };
      
      http.put("/user/save-basic-info/", dataToSave).then(resp => {
        this.setState({ loading_basic_info_form: false });
        localStorage.setItem("timezone", this.state.data.timezone);
        this.formShowSuccess("basic_info_form", "Your changes have been saved!");
      }).catch((error) => {
        this.setState({ loading_basic_info_form: false, error_basic_info_form: getErrorMessage(error) });
      });
    }

    event.preventDefault();
  }

  submitPasswordForm(event) {
    this.setState({ error_password_form: "" });
    
    if(checkForm(event.target)) {
      
      if(this.state.data.new_password !== this.state.data.new_password_confirmation) {
        this.setState({ error_password_form: "These two passwords do not match" });
      } else {
        document.activeElement.blur();
        this.setState({ loading_password_form: true });
  
        const dataToSave = {
          new_password: this.state.data.new_password
        };
        
        http.put("/user/save-new-password/", dataToSave).then(resp => {

          const copyOfDataObject = { ...this.state.data };
          copyOfDataObject.new_password = "";
          copyOfDataObject.new_password_confirmation = "";
          
          this.setState({ data: copyOfDataObject, loading_password_form: false });
          document.getElementById("password_form").reset();
          this.formShowSuccess("password_form", "Your new password has been saved!");

        }).catch((error) => {
          this.setState({ loading_password_form: false, error_password_form: getErrorMessage(error) });
        });
      }
    }

    event.preventDefault();
  }
  
  render() {
    
    return (
      <div>

        {/* PLAN USAGE */}
        <SubscriptionPanel user={this.state.user} loadingUserData={this.state.loadingUserData} />

        {/* BASIC INFO FORM */}
        <Panel wide spaced hasTitle>

          <PanelTitle>Basic Info</PanelTitle>
        
          <form id="basic_info_form" name="basic_info_form" onSubmit={this.submitBasicInfoForm} noValidate className={`${this.state.loading_basic_info_form ? 'loading' : ''}`}>
            <div className="flex-container">

              <div className="flex-item flex-item--50">
                <label htmlFor="first_name" className="input-label">First Name</label>
                <input type="text" name="first_name" onChange={this.handleInputChange} defaultValue={this.state.data.first_name} id="first_name" className="input-field" required data-error-text="Please enter your first name" />
              </div>

              <div className="flex-item flex-item--50">
                <label htmlFor="last_name" className="input-label">Last Name</label>
                <input type="text" name="last_name" onChange={this.handleInputChange} defaultValue={this.state.data.last_name} id="last_name" className="input-field" required data-error-text="Please enter your last name" />
              </div>

              <div className="flex-item">
                <label htmlFor="email" className="input-label">Email Address</label>
                <input type="email" name="email" onChange={this.handleInputChange} defaultValue={this.state.data.email} id="email" className="input-field" required data-error-text="Please enter a valid email address" />
              </div>

              <div className="flex-item">
                <label htmlFor="timezone" className="input-label">Timezone</label>
                <div className="select-wrapper select-wrapper--timezone">
                  <TimezonePicker
                    id="timezone"
                    name="timezone"
                    className="input-field--timezone"
                    onChange={this.handleTimezoneChange}
                    value={this.state.data.timezone}
                    inputProps={{
                      placeholder: 'Select Timezone...',
                      name: 'timezone',
                    }}
                  />
                </div>
              </div>

              <div className="flex-item">
                <label htmlFor="date_format_pref" className="input-label">Date Format Preference</label>
                <div className="select-wrapper select-wrapper--timezone">
                  <select
                    id="date_format_pref"
                    name="date_format_pref"
                    className="input-field"
                    onChange={this.handleInputChange}
                    value={this.state.data.date_format_pref}
                  >
                    <option value="MM-DD-YYYY">MM-DD-YYYY</option>
                    <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                    <option value="YYYY-MM-DD">YYYY-MM-DD</option>
                  </select>
                </div>
              </div>

              {this.formSuccess("basic_info_form")}
              {this.formError("basic_info_form")}

              <div className="flex-item centered">
                <input type="submit" value="Save Changes" className="btn btn--wide btn--push-top" />
              </div>

            </div>
          </form>

          <Loading loading={this.state.loading_basic_info_form} />
          
        </Panel>

        {/* CHANGE PASSWORD FORM */}
        <Panel wide spaced hasTitle>

          <PanelTitle>Change Password</PanelTitle>
        
          <form id="password_form" name="password_form" onSubmit={this.submitPasswordForm} autoComplete="off" noValidate className={`${this.state.loading_password_form ? 'loading' : ''}`}>
            <div className="flex-container">

              <div className="flex-item">
                <label htmlFor="new_password" className="input-label">New Password</label>
                <input type="password" name="new_password" onChange={this.handleInputChange} id="new_password" className="input-field" required data-error-text="Please enter a new password" />
              </div>

              <div className="flex-item">
                <label htmlFor="new_password_confirmation" className="input-label">Confirm New Password</label>
                <input type="password" name="new_password_confirmation" onChange={this.handleInputChange} id="new_password_confirmation" className="input-field" required data-error-text="Please confirm your new password" />
              </div>

              {this.formSuccess("password_form")}
              {this.formError("password_form")}

              <div className="flex-item centered">
                <input type="submit" value="Save New Password" className="btn btn--wide btn--push-top" />
              </div>

            </div>
          </form>

          <Loading loading={this.state.loading_password_form} />
          
        </Panel>

        {/* THIRD-PARTY SYNC APPS */}
        <SyncAppsPanel user={this.state.user} updateUserData={this.updateUserData} loadingUserData={this.state.loadingUserData} />
        
        <div className="chat-widget-breathing-room"></div>
        
      </div>
    );
  }
}

export default withRouter(Settings);