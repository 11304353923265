import React from "react";
import loading from "./img/loading.svg";

const Loading = (props) => {

  if(props.loading) {
    return (
      <div className={`loading-placeholder ${props.className}`}>
        <img src={loading} alt="" />
      </div>
    );
  }

  return null;
}

export default Loading;