import React from "react";
import { withRouter, Link } from "react-router-dom";
import http, { getErrorMessage } from "../utility/http";
import checkForm from "../utility/validator";

// UI
import Panel, { PanelTitle } from "../ui/Panel";
import Loading from "../ui/Loading";

class CreateNewPassword extends React.Component {

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      data: {
        fpk: this.props.match.params.fpk,
        new_password: "",
        new_password_confirmation: "",
      },
      loading: false,
      success: false,
      error: ""
    };
  }
  
  componentDidMount() {
    this.props.setSection("login");

    // Redirect logged-in users to the main app
    if(localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
      this.props.history.push("/");
    }
  }

  handleInputChange({target}) {
    const copyOfDataObject = { ...this.state.data, [target.name]: target.value };
    this.setState({ data: copyOfDataObject });
  }

  formError() {
    return this.state.error ? <div className="form-error">{this.state.error}</div> : null;
  }

  submit(event) {
    this.setState({ error: "" });
    
    if(checkForm(event.target)) {
      
      if(this.state.data.new_password !== this.state.data.new_password_confirmation) {
        this.setState({ error: "These two passwords do not match" });
      } else {
        document.activeElement.blur();
        this.setState({ loading: true });
  
        const dataToPost = {
          fpk: this.state.data.fpk,
          new_password: this.state.data.new_password
        };
        
        http.post("/user/create-new-password/", dataToPost).then(resp => {
          
          localStorage.setItem("access_token", resp.data.access_token);
          localStorage.setItem("refresh_token", resp.data.refresh_token);
          localStorage.setItem("timezone", resp.data.timezone);

          this.setState({ loading: false, success: true });
          
        }).catch((error) => {
          this.setState({ loading: false, error: getErrorMessage(error) });
        });
      }
    }

    event.preventDefault();
  }
  
  render() {

    const Success = () => {
      return (
        <div>
          <p className="centered">
            <span className="large-emoji" role="img" aria-label="Checkbox">☑️</span><br/>
            <strong>Password Updated</strong>
          </p>
          <p className="centered">
            <Link to="/" className="btn">Go to Dashboard</Link>
          </p>
        </div>
      );
    };
    
    return (
      <div>

        <Panel spaced hasTitle>

          <PanelTitle>Create New Password</PanelTitle>
          
          <form name="create_new_password_form" onSubmit={this.submit} noValidate className={`${this.state.loading ? 'loading' : ''} ${this.state.success ? 'form--hidden' : ''}`}>
            <div className="flex-container">

              <div className="flex-item">
                <label htmlFor="new_password" className="input-label">New Password</label>
                <input type="password" name="new_password" autoFocus onChange={this.handleInputChange} id="new_password" className="input-field" required data-error-text="Please enter a new password" />
              </div>

              <div className="flex-item">
                <label htmlFor="new_password_confirmation" className="input-label">Confirm New Password</label>
                <input type="password" name="new_password_confirmation" onChange={this.handleInputChange} id="new_password_confirmation" className="input-field" required data-error-text="Please confirm your new password" />
              </div>

              {this.formError()}

              <div className="flex-item centered">
                <input type="submit" value="Save New Password" className="btn btn--wide btn--push-top" />
              </div>
  
            </div>
          </form>

          {this.state.success ? <Success/> : null}
          
          <Loading loading={this.state.loading} />

        </Panel>
        
      </div>
    );
  }
}

export default withRouter(CreateNewPassword);