import http from '../utility/http';

const plan = (callback) => {

  const now = new Date().valueOf();
  const cachedPlan = localStorage.getItem('plan');
  
  if(cachedPlan) {
    const data = JSON.parse(cachedPlan);

    // The cached plan data has expired, reload it from the database
    if(data.cache_expires <= now) {
      fetchPlanData(now, callback);
    }
    
    return callback(data);
  } else {
    fetchPlanData(now, callback);
  }

};

const fetchPlanData = (current_timestamp, callback) => {
  http.get('/user/plan/').then(resp => {

    // Cache the plan data for one hour (60 minutes)
    const one_hour = 60 * (60 * 1000);
    resp.data.cache_expires = current_timestamp + one_hour;
    
    localStorage.setItem('plan', JSON.stringify(resp.data));
    return callback(resp.data);
  }).catch((error) => {
    return callback(false);
  });
};

const clearCachedPlanInfo = () => {
  localStorage.removeItem('plan');
};

export default plan;
export { clearCachedPlanInfo };