import React from "react";
import { withRouter, Link } from "react-router-dom";
import http from "../../utility/http";
import plan from "../../factories/plan";

// UI
import SubNav, { SubNavText, SubNavLink, SubNavSep } from "../../ui/SubNav";
import NoResults from "../../ui/NoResults";
import Loading from "../../ui/Loading";

class PageLimitReached extends React.Component {
  
  constructor() {
    super();

    this.state = {
      user: {},
      plan: {},
      loading_user: true,
      loading_plan: true
    };
  }
  
  componentDidMount() {
    this.props.setSection("screenshots");

    // Get the user's info
    http.get("/user/").then(resp => {
      this.setState({
        user: resp.data,
        loading_user: false
      });
    }).catch((error) => {
      console.error(error);
    });
    
    // Get the user's plan information
    plan(resp => {
      if(resp.plan.name === "trial") {
        this.setState({
          plan: resp,
          loading_plan: false
        });
      } else {
        this.props.history.push("/settings/manage-subscription");
      }
    });
  }

  render() {

    const Body = () => {
      if(this.state.loading_user || this.state.loading_plan) {
        return <Loading loading={true} />;
      } else {
        return (
          <NoResults>
            <p><strong>Page Limit Reached</strong></p>
            <p>Your trial is limited to {this.state.plan.plan.limits.pages} pages. You can upgrade now to get more pages and access to other great features!</p>
            <Link to={`/subscribe/${this.state.user.id}/${this.state.user.trial_key}`} className="btn btn--dual">Upgrade Now</Link>
            <Link to="/" className="btn btn--secondary btn--dual">Back to Pages</Link>
          </NoResults>
        );
      }
    }
    
    return (
      <div>

        <SubNav>
          <SubNavLink to="/">Pages</SubNavLink>
          <SubNavSep />
          <SubNavText>Limit Reached</SubNavText>
        </SubNav>

        <Body/>

      </div>
    );
  }
}

export default withRouter(PageLimitReached);