import React from "react";
import { withRouter, Link } from "react-router-dom";
import http from "../../utility/http";
import plan from "../../factories/plan";

// UI
import SubNav, { SubNavText, SubNavButton } from "../../ui/SubNav";
import Table from "../../ui/Table";
import NoResults from "../../ui/NoResults";
import Loading from "../../ui/Loading";
import TableRow from "../../ui/TableRow";
import TableCell from "../../ui/TableCell";
import PageItem from "./PageItem";
import Notification from "./Notification";

class PageList extends React.Component {
  
  constructor() {
    super();
    
    this.state = {
      loading: true,
      plan: {}
    };
  }

  componentDidMount() {
    this.props.setSection("screenshots");

    // Get the user's plan information
    plan(resp => this.setState({ plan: resp }));
    
    this.getPages();

    this.setState({
      getInverval: setInterval(() => this.getPages(), 30000)
    });

    // Check for notification messages in localStorage
    if(localStorage.getItem("timezone_preference_missing")) {
      this.setState({
        notification: {
          text: "Select a timezone for screenshot scheduling",
          link: "/settings",
          dismiss: () => {
            localStorage.removeItem("timezone_preference_missing");
            this.setState({ notification: null });
          }
        }
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.getInverval);
  }

  getPages() {

    // Get all of the user's pages
    http.get("/page/").then(resp => {
      this.setState({ loading: false, pages: resp.data });
    });
  }
  
  render() {

    let Body = () => <Loading loading={this.state.loading} />;

    if(this.state.pages && this.state.pages.length) {

      const pages = this.state.pages.map(page => {
        return (
          <PageItem key={page.id} data={page} />
        );
      });

      Body = () => {
        return (
          <div className="table-wrapper">
            <Table>
              <TableRow header>
                <TableCell>Name</TableCell>
                <TableCell>URL</TableCell>
                <TableCell medium>Frequency</TableCell>
                <TableCell medium>Next Screenshot</TableCell>
                <TableCell veryShort># Imgs</TableCell>
                <TableCell veryShort>Status</TableCell>
                <TableCell veryShort></TableCell>
              </TableRow>
              {pages}
            </Table>
            <div className="chat-widget-breathing-room"></div>
          </div>
        );
      };
      
    } else if((!this.state.pages || this.state.pages.length === 0) && !this.state.loading) {

      Body = () => {
        return (
          <NoResults>
            <p><strong>Welcome to Screenlapse!</strong></p>
            <p>Get started by adding a few web pages to be captured.</p>
            <Link to="/page/add" className="btn">Add Your First Page</Link>
          </NoResults>
        );
      };
      
    }
    
    return (
      <div>
        
        <SubNav>
          <SubNavText>Pages</SubNavText>
          <SubNavButton to="/page/add">Add New Page</SubNavButton>
        </SubNav>
        
        <Body />

        <Notification data={this.state.notification} />

      </div>
    );
  }
}

export default withRouter(PageList);