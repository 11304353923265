/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { clearCachedPlanInfo } from "../factories/plan";
import http, { getErrorMessage } from "../utility/http";
import checkForm from "../utility/validator";

// UI
import Panel, { PanelTitle } from "../ui/Panel";
import Loading from "../ui/Loading";
import PricingPlans from "../ui/PricingPlans";
import stripeBadge from "../ui/img/stripe.svg";
import Emoji from "../ui/Emoji";

class Subscribe extends React.Component {

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleCardInputChange = this.handleCardInputChange.bind(this);
    this.selectPlan = this.selectPlan.bind(this);

    this.state = {
      current_plan_name: "",
      data: {
        user_id: this.props.match.params.userID,
        trial_key: this.props.match.params.trialKey,
        plan: "professional",
        card: {
          id: "",
          number: "",
          exp_month: "",
          exp_year: "",
          cvc: ""
        }
      },
      loading: false,
      error: ""
    };
  }
  
  componentDidMount() {
    this.props.setSection("subscribe");

    // Stripe setup
    window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_PUB_KEY);

    // If the user is logged in, get their plan info.
    // If they're not a trial user, redirect them to the Manage Subscription page.
    if(localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
      const plan = JSON.parse(localStorage.getItem("plan")) || null;
      if(plan) {
        this.setState({ current_plan_name: plan.plan.name });
      }
    }
  }

  handleCardInputChange({target}) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.card[target.name] = target.value;
    this.setState({ data: copyOfDataObject });
  }

  selectPlan(e, plan) {
    const copyOfDataObject = {...this.state.data};
    copyOfDataObject.plan = plan;
    this.setState({ data: copyOfDataObject });
    e.preventDefault();
  }

  formError() {
    return this.state.error ? <div className="form-error">{this.state.error}</div> : null;
  }

  submit(event) {
    this.setState({ error: "" });
    document.activeElement.blur();

    if(checkForm(event.target)) {
      this.setState({ loading: true });

      window.Stripe.createToken(event.target, (resp, data) => {
        
        if(data.error) {
          this.setState({ error: data.error.message, loading: false });
        } else {
          
          const copyOfData = {...this.state.data};
          copyOfData.stripe_token = data.id;
          
          copyOfData.card = {
            id: data.card.id,
            exp_month: data.card.exp_month,
            exp_year: data.card.exp_year,
            last4: data.card.last4,
            brand: data.card.brand
          };
          
          this.setState({ data: copyOfData });
          this.completeSubscription();
        }
      });
    }
    
    event.preventDefault();
  }
  
  completeSubscription() {
    clearCachedPlanInfo();
    
    http.post("/user/subscribe/", this.state.data).then(resp => {
      localStorage.setItem("access_token", resp.data.access_token);
      localStorage.setItem("refresh_token", resp.data.refresh_token);

      // Fathom Analytics Event - New Customer
      if(process.env.REACT_APP_ENV === "production") {
        if(typeof window.fathom !== "undefined") {
          window.fathom.trackGoal('MW0RFY1Q', (resp.data.price * 100));
        }
      }
      
      this.props.history.push("/settings");

    }).catch((error) => {
      this.setState({ loading: false, error: getErrorMessage(error) });
    });
  }
  
  render() {

    if(this.state.current_plan_name && this.state.current_plan_name !== "trial") {
      return <Redirect to="/settings/manage-subscription" />;
    }

    // Year Options
    const YearOptions = () => {
      let year = new Date().getFullYear();
      let years = [];
      
      for(let i = 0; i < 21; i++) {
        years.push(<option key={i} value={year + i}>{year + i}</option>);
      }

      return years;
    };
    
    return (
      <div>

        <div className="content-area centered">
          {/* `current_plan_name` is set if the user is logged in. Otherwise they're upgrading from a logged-out state. */}
          <h2>{this.state.current_plan_name ? "Get the most out of Screenlapse" : "We hope you enjoyed your free trial!"}</h2>
          <p>Select the plan that best suits your needs. You can change plans or cancel at any time.</p>
        </div>

        <PricingPlans plan={this.state.data.plan} selectPlan={this.selectPlan} />

        <div className="content-area content-area--extra-mentions centered">
          
          <p className="feature-p">
            <Emoji label="Filing cabinet">🗄</Emoji>
            All screenshots are retained for 2 years
          </p>

          <br/>
          
          <p className="feature-p">
            <Emoji label="Down arrow">⬇️</Emoji>
            All screenshots can be downloaded at any time
          </p>
            
          <br/>
          
          <p className="feature-p">
            <Emoji label="Lock and key">🔐</Emoji>
            Secure payments <a href="https://stripe.com" target="_blank" rel="noopener noreferrer"><img src={stripeBadge} title="Secure Payments with Stripe" alt="Stripe.com Badge" className="stripe-badge" /></a>
          </p>

        </div>
        
        <Panel spaced semiWide hasTitle>

          <PanelTitle>Payment Information</PanelTitle>
          
          <form name="subscribe_form" onSubmit={this.submit} noValidate className={`${this.state.loading ? 'loading' : ''}`}>
            <div className="flex-container">

              <div className="flex-item">
                <label htmlFor="number" className="input-label">Credit Card #</label>
                <input type="text" name="number" minLength="10" size="20" maxLength="20" onChange={this.handleCardInputChange} defaultValue={this.state.data.card.number} id="number" data-stripe="number" className="input-field" required />
              </div>

              <div className="flex-item flex-item--card-exp-date">
                <label htmlFor="exp_month" className="input-label">Card Expiration</label>

                <div className="select-wrapper select-wrapper--super-short">
                  <select name="exp_month" id="exp_month" className="input-field" onChange={this.handleCardInputChange} defaultValue={this.state.data.card.exp_month} data-stripe="exp-month" required>
                    <option value="">MM</option>
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                    <option value="4">04</option>
                    <option value="5">05</option>
                    <option value="6">06</option>
                    <option value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>

                <div className="select-wrapper select-wrapper--short">
                  <select name="exp_year" id="exp_year" className="input-field" onChange={this.handleCardInputChange} value={this.state.data.card.exp_year} data-stripe="exp-year" required>
                    <option value="">YYYY</option>
                    <YearOptions/>
                  </select>
                </div>
                
              </div>

              <div className="flex-item flex-item--card-cvc">
                <label htmlFor="email" className="input-label">CVC</label>
                <input type="text" name="cvc" onChange={this.handleCardInputChange} defaultValue={this.state.data.card.cvc} id="cvc" minLength="3" size="4" maxLength="4" data-stripe="cvc" className="input-field input-field--short" required />
              </div>

              {this.formError()}

              <div className="flex-item centered">
                <input type="submit" value="Submit" className="btn btn--wide btn--push-top" />
              </div>
  
            </div>
          </form>
          
          <Loading loading={this.state.loading} />

        </Panel>

      </div>
    );
  }
}

export default withRouter(Subscribe);