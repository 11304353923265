import React from "react";
import { withRouter } from "react-router-dom";

// UI
import NoResults from "../../ui/NoResults";

class NoScreenshots extends React.Component {
  
  render() {
    return (
      <NoResults>
        There aren't any screenshots for this page yet.
      </NoResults>
    );
  }
}

export default withRouter(NoScreenshots);