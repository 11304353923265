import React from "react";

const NoResults = (props) => (
  <div className="no-results">
    <div className="no-results-content">
      {props.children}
    </div>
  </div>
);

export default NoResults;