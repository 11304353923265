import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrowRight from "./img/arrow-right.svg";

const SubNavText = (props) => <span className="sub-nav-item">{props.children}</span>;
const SubNavLink = (props) => <Link to={props.to} className="sub-nav-link">{props.children}</Link>;
const SubNavButton = (props) => <Link to={props.to} className={`btn btn--sm sub-nav-btn ${props.secondary ? 'btn--secondary' : ''}`}>{props.children}</Link>;
const SubNavButtonSubstitute = (props) => <span className="sub-nav-btn-substitute">{props.children}</span>;
const SubNavSep = (props) => <span className="sub-nav-separator"><img src={arrowRight} alt="Arrow" /></span>;

class SubNav extends Component {
  render() {
    return (
      <nav className="sub-nav clearfix">
        {this.props.children}
      </nav>
    );
  }
}

export default SubNav;
export { SubNavText, SubNavLink, SubNavButton, SubNavButtonSubstitute, SubNavSep };