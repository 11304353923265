import React from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import http, { getErrorMessage } from "../utility/http";
import checkForm from "../utility/validator";

// UI
import Panel, { PanelTitle } from "../ui/Panel";
import Loading from "../ui/Loading";
import GoogleLogoMark from "../ui/img/google-logo-mark.svg";

class Login extends React.Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      data: {
        email: "",
        password: "",
      },
      loading: false,
      error: ""
    };
  }
  
  componentDidMount() {
    this.props.setSection("login");
  }

  handleInputChange({target}) {
    const copyOfDataObject = { ...this.state.data, [target.name]: target.value };
    this.setState({ data: copyOfDataObject });
  }

  formError() {
    return this.state.error ? <div className="form-error">{this.state.error}</div> : null;
  }

  submit(event) {
    const redirect = this.props.location.search.split("?redirect=")[1];
    this.setState({ error: "" });
    
    if(checkForm(event.target)) {
      this.setState({ loading: true });
      
      http.post("/user/login/", this.state.data).then(resp => {

        localStorage.setItem("access_token", resp.data.access_token);
        localStorage.setItem("refresh_token", resp.data.refresh_token);
        localStorage.setItem("timezone", resp.data.timezone);

        // Provide the chat widget with some user identifiers
        try {
          window.tidioChatApi.setVisitorData({
            distinct_id: resp.data.id,
            email: resp.data.email,
            name: resp.data.first_name + " " + resp.data.last_name
          });
        } catch {
          console.error(`Couldn't set Tidio visitor data.`);
        }
        
        this.props.history.push(redirect ? redirect : "/");
        
      }).catch((error) => {
        if(error.response.data && error.response.data.error === "trial_ended") {
          this.props.history.push(`/subscribe/${error.response.data.user_id}/${error.response.data.trial_key}`);
        } else {
          this.setState({ loading: false, error: getErrorMessage(error) });
        }
      });
    }

    event.preventDefault();
  }
  
  render() {

    // Redirect logged-in users to the main app
    if(localStorage.getItem("access_token") && localStorage.getItem("refresh_token")) {
      return <Redirect to="/" />;
    }
    
    return (
      <div>

        <Panel spaced hasTitle>

          <PanelTitle>Log In</PanelTitle>
          
          <form name="login_form" onSubmit={this.submit} noValidate className={`${this.state.loading ? 'loading' : ''}`}>
            <div className="flex-container">

              <div className="flex-item">
                <label htmlFor="email" className="input-label">Email Address</label>
                <input type="email" name="email" autoFocus onChange={this.handleInputChange} value={this.state.data.email} id="email" className="input-field" required data-error-text="Please enter a valid email address" />
              </div>
              
              <div className="flex-item">
                <label htmlFor="password" className="input-label">Password</label>
                <input type="password" name="password" onChange={this.handleInputChange} value={this.state.data.password} id="password" className="input-field" required data-error-text="Please enter your password" />
              </div>

              {this.formError()}

              <div className="flex-item centered">
                <input type="submit" value="Log In" className="btn btn--wide btn--push-top" />
              </div>
  
            </div>
          </form>

          <Loading loading={this.state.loading} />

          <div className="oauth-options">

            <span className="or">
              <span className="or__text">or</span>
            </span>

            <a href={`${process.env.REACT_APP_API_HOST}/user/login/oauth`} className="google-oath-button">
              <img src={GoogleLogoMark} alt="Google" />
              <span>Sign in with Google</span>
            </a>

          </div>

        </Panel>

        <div className="secondary-action-area secondary-action-area--narrow centered">
          <p><Link to="/forgot-password" className="subtle-link">Forgot your password?</Link></p>
        </div>
        
      </div>
    );
  }
}

export default withRouter(Login);