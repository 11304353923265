import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import logo from "./img/logo.svg";

class Header extends Component {
  render() {
    
    const Nav = () => {
      if(localStorage.getItem("access_token")) {
        return (
          <nav className="nav">
            <Link to="/" className={`nav-link ${this.props.section === 'screenshots' ? 'nav-link--active': ''}`}>Screenshots</Link>
            <Link to="/settings" className={`nav-link ${this.props.section === 'settings' ? 'nav-link--active': ''}`}>Settings</Link>
            <Link to="/logout" className="nav-link nav-link--float-right">Log Out</Link>
          </nav>
        );
      } else {
        return (
          <nav className="nav">
            {[ "/signup", "/pricing", "/forgot-password", "/subscribe", "/oauth" ].indexOf(this.props.location.pathname) > -1 || this.props.location.pathname.indexOf("/create-new-password") > -1 ? <Link to="/login" className="nav-link nav-link--float-right">Log In</Link> : null}
            {[ "/login", "/goodbye", "/oauth" ].indexOf(this.props.location.pathname) > -1 ? <Link to="/signup" className="nav-link nav-link--float-right">Sign Up</Link> : null}
            {[ "/subscribe", "/oauth" ].indexOf(this.props.location.pathname) === -1 ? <a href="https://screenlapse.com/pricing" className="nav-link nav-link--float-right">Pricing</a> : null}
          </nav>
        );
      }
    };

    const Logo = () => {
      if(localStorage.getItem("access_token")) {
        return (
          <Link to="/" className="logo">
            <img src={logo} alt="Lapse" className="logo-image" />
          </Link>
        );
      } else {
        return (
          <a href="https://screenlapse.com" className="logo">
            <img src={logo} alt="Lapse" className="logo-image" />
          </a>
        );
      }
    }
    
    return (
      <header className={`header ${localStorage.getItem("access_token") ? 'header--logged-in' : 'header--logged-out'}`}>
        <Logo/>
        <Nav/>
      </header>
    );
  }
}

export default withRouter(Header);