import React from "react";
import { withRouter, Link } from "react-router-dom";

// UI
import ErrorCard from "../../ui/ErrorCard";

const TakeScreenshotResult = (props) => {
  if(props.success && !props.errors.length) {
    return (
      <div>
        <p><strong>Success!</strong> Your screenshot has been saved!</p>
        <p>
          <Link to={`/page/${props.match.params.id}`} className="btn" data-test-id="view-screenshot">View Screenshot</Link>
        </p>
      </div>
    );
  } else if(props.success && props.errors.length) {
    return (
      <div>
        <p><strong>Success!</strong> Your screenshot has been saved!<br/>But there were some errors:</p>
        {props.errors.map((err, idx) => <ErrorCard error={err} key={idx} /> )}
        <p>
          <Link to={`/page/${props.match.params.id}`} className="btn btn--dual" data-test-id="view-screenshot">View Screenshot</Link>
          <Link to={`/page/${props.match.params.id}/edit`} className="btn btn--secondary btn--dual">Edit Page Settings</Link>
        </p>
      </div>
    );
  } else if(!props.success) {
    return (
      <div>
        {props.error}
        {props.errors ? props.errors.map((err, idx) => <ErrorCard error={err} key={idx} /> ) : ''}
        <p>
          <Link to={`/page/${props.match.params.id}/edit`} className="btn">Edit Page Settings</Link>
        </p>
      </div>
    );
  }
}

export default withRouter(TakeScreenshotResult);