/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";

// UI
import Panel, { PanelTitle } from "../ui/Panel";
import Emoji from "../ui/Emoji";

class PageNotFound extends React.Component {

  componentDidMount() {
    this.props.setSection("404");
  }
  
  render() {
    return (
      <div>

        <Panel spaced centered hasTitle>
          
          <PanelTitle>Page Not Found</PanelTitle>
          
          <p>
            <span className="large-emoji">
              <Emoji>❓</Emoji>
            </span>
            <br/>
            Sorry, this page doesn't seem to exist.
          </p>
        </Panel>

      </div>
    );
  }
}

export default PageNotFound;