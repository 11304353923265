import React, { Component } from "react";

class TableCell extends Component {
  render() {
    return (
      <div className={`table-cell ${this.props.hoverable ? 'table-cell--hoverable' : ''} ${this.props.ra ? 'align-right' : ''} ${this.props.short ? 'table-cell--short' : ''} ${this.props.veryShort ? 'table-cell--very-short' : ''} ${this.props.medium ? 'table-cell--medium' : ''}`}>
        <span className="table-cell-content">
          {this.props.children}
        </span>
      </div>
    );
  }
}

export default TableCell;