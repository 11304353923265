import React from "react";
import Moment from "react-moment";

// Usage: calendar={calendarStrings}
// const calendarStrings = {
//   lastDay: "[Yesterday -] MMM D, YYYY @ h:mm A",
//   sameDay: "[Today -] MMM D, YYYY @ h:mm A",
//   nextDay: "[Tomorrow -] MMM D, YYYY @ h:mm A",
//   lastWeek: "MMM D, YYYY @ h:mm A",
//   nextWeek: "MMM D, YYYY @ h:mm A",
//   sameElse: "MMM D, YYYY @ h:mm A"
// };

const NextScreenshotInfo = (props) => {
  if(props.data.active) {
    return (
      <span>
        <Moment format="MMM D, YYYY @ h:mm A" interval={0}>{props.data.next_capture_date.$date}</Moment> <span>{ new Date().valueOf() >= props.data.next_capture_date ? '(processing)' : '' }</span>
      </span>
    );
  }

  return "--";
}

export default NextScreenshotInfo;