import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import plan from "../../factories/plan";

// UI
import Panel, { PanelTitle, PanelSection } from "../../ui/Panel";
import Loading from "../../ui/Loading";

const SubscriptionPanel = (props) => {
  
  const [ loadingPlanData, setLoadingPlanData ] = useState(true);
  const [ planInfo, setPlanInfo ] = useState({});
  const [ percentages, setPercentages ] = useState({});
  
  // Initial component load
  useEffect(() => {

    // Get the user's plan usage
    plan(resp => {

      // Calculate usage percentages
      const pages_pct = parseInt((resp.usage.pages / resp.plan.limits.pages) * 100);
      const hourly_pages_pct = parseInt((resp.usage.hourly_pages / resp.plan.limits.hourly_pages) * 100);
      const proxy_bandwidth_pct = parseInt((resp.usage.proxy_bandwidth / resp.plan.limits.proxy_bandwidth) * 100);
      
      setPercentages({
        pages: pages_pct,
        hourly_pages: hourly_pages_pct,
        proxy_bandwidth: proxy_bandwidth_pct,
      });

      setPlanInfo(resp);
      setLoadingPlanData(false);
    });

  }, []);
  
  const PlanDetails = () => {
    if(props.user.date_created && props.user.trial_ends && props.user.plan.name === "trial") {
      const todays_date = new Date();
      const trial_ends_date = new Date(props.user.trial_ends.$date);
      
      if(trial_ends_date > todays_date) {
        return (
          <p>
            Your free trial ends in <Moment fromNow ago interval={0}>{trial_ends_date}</Moment>
          </p>
        );
      } else {
        return (
          <p>
            Your free trial has expired
          </p>
        );
      }
    } else {
      return (
        <p>You're on the <span className="capitalize">{props.user.plan.name.replace('_', ' ')}</span> plan (${props.user.plan.price}/month)</p>
      );
    }
  };
  
  const PlanCTA = () => {

    // For trial users, show the button to upgrade before their trial is over
    if(planInfo.plan.name === "trial") {
      return <Link to={`/subscribe/${props.user.id}/${props.user.trial_key}`} className="btn btn--sm">Upgrade Now</Link>;
    
    // If the user isn't on the top plan yet, show an "Upgrade" button
    } else {
      return <Link to="/settings/manage-subscription" className="btn btn--secondary btn--sm">Manage</Link>;
    }
  };

  return (
    <Panel wide spaced hasTitle noPadding>

      <PanelTitle>Your Plan</PanelTitle>

      {!(loadingPlanData || props.loadingUserData) ?
        <div>

          <PanelSection>

            <div className={`usage-graph ${planInfo.usage.pages >= planInfo.plan.limits.pages ? 'usage-graph--full' : ''}`}>
              <h3 className="usage-graph__title">
                <span>Pages</span>
                <span>
                  {planInfo.usage.pages} <span className="sep">/</span> {planInfo.plan.limits.pages}
                </span>
              </h3>
              <div className="usage-graph__bar">
                <div className="usage-graph__inside" style={{ width: `${percentages.pages}%` }}></div>
              </div>
            </div>

            <div className={`usage-graph ${planInfo.usage.hourly_pages >= planInfo.plan.limits.hourly_pages ? 'usage-graph--full' : ''}`}>
              <h3 className="usage-graph__title">
                <span>Hourly Pages</span>
                <span>
                  {planInfo.usage.hourly_pages} <span className="sep">/</span> {planInfo.plan.limits.hourly_pages}
                </span>
              </h3>
              <div className="usage-graph__bar">
                <div className="usage-graph__inside" style={{ width: `${percentages.hourly_pages}%` }}></div>
              </div>
            </div>

            <div className={`usage-graph ${planInfo.usage.proxy_bandwidth >= planInfo.plan.limits.proxy_bandwidth ? 'usage-graph--full' : ''}`}>
              <h3 className="usage-graph__title">
                <span>
                  Proxy Bandwidth
                  <span className="color--grey-medium"> (resets on {planInfo.proxy_bandwidth_reset_month} 1st)</span>
                </span>
                <span>
                  {planInfo.usage.proxy_bandwidth.toLocaleString('en-US')} MB <span className="sep">/</span> {planInfo.plan.limits.proxy_bandwidth.toLocaleString('en-US')} MB
                </span>
              </h3>
              <div className="usage-graph__bar">
                <div className="usage-graph__inside" style={{ width: `${percentages.proxy_bandwidth}%` }}></div>
              </div>
            </div>

          </PanelSection>

          <PanelSection cta={<PlanCTA/>}>
            <h3>Subscription</h3>
            <PlanDetails/>
          </PanelSection>

          {props.user.card ?
            <PanelSection cta={
              <Link to="/settings/update-card" className="btn btn--secondary btn--sm">Update Card</Link>
            }>
              <h3>Payment Method</h3>
              <p>
                {props.user.card.brand}
                &nbsp;ending in {props.user.card.last4}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Exp: {props.user.card.exp_month}/{props.user.card.exp_year}
              </p>
            </PanelSection>
          : null} 
          
        </div>
      : null}

      <Loading loading={loadingPlanData || props.loadingUserData} />
      
    </Panel>
  );
};

export default SubscriptionPanel;