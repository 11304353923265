import React from "react";
import { withRouter } from "react-router-dom";

class SubscriptionCancelled extends React.Component {  
  render() {
    return (
      <div>

        <div className="content-area">
          
          <h2>Subscription Cancelled</h2>
          <p>I'm sorry Screenlapse didn't work for you. I would <em>greatly appreciate</em> any feedback you can provide on your experience.</p>

          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdG-orpkzLqCUUnyabF8yrIb972SimqDRbWiOoudYjBcrWMBg/viewform?embedded=true" width="640" height="1280" title="Cancellation Survey" frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>

        </div>
      
      </div>
    );
  }
}

export default withRouter(SubscriptionCancelled);